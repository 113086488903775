<script>
export default {
  name: "GameLeaderboard",
  props: {
    Ranking: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    RunnerUp() {
      return this.Ranking.slice(3);
    },
  },
  methods: {
    overflowed(value) {
      if (!value) return "";
      const preferLen = 4;
      if (typeof value === "string" && value.length >= preferLen) {
        return (
          value.slice(0, 2) + "*".repeat(value.length - 4) + value.slice(-2)
        );
      } else {
        return value;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="gradient-text title">{{ $t('bitkachu.leaderboard') }}</div>
    <div class="footnote">{{ $t('bitkachu.footnote') }}</div>
    <div class="leaderboard" v-if="Ranking && Ranking.length > 0">
      <div class="top">
        <div class="second">
          <div class="tit">2ND</div>
          <div class="avatar">
            <img src="@/assets/images/sonic.png" alt="" />
          </div>
          <div class="content">
            <div class="name">
              {{
                Ranking[1].username
                  ? overflowed(Ranking[1].username)
                  : overflowed("Anonymous")
              }}
            </div>
            <div class="earning">
              {{ TruncateToDecimals2(Ranking[1].totalBet || 0, "", 2) }}
              <img class="currency" src="@/assets/images/currency/bs.png" />
            </div>
          </div>
        </div>
        <div class="first">
          <div class="tit">
            <img
              class="king"
              src="@/assets/images/icons/firstplace.svg"
              alt=""
            />
            1ST
          </div>
          <div class="avatar">
            <img src="@/assets/images/pikatwo.png" alt="" />
          </div>
          <div class="content">
            <div class="name">
              {{
                Ranking[0].username
                  ? overflowed(Ranking[0].username)
                  : overflowed("Anonymous")
              }}
            </div>
            <div class="earning">
              {{ TruncateToDecimals2(Ranking[0].totalBet || 0, "", 2) }}
              <img class="currency" src="@/assets/images/currency/bs.png" />
            </div>
          </div>
        </div>
        <div class="third">
          <div class="tit">3RD</div>
          <div class="avatar">
            <img src="@/assets/images/jigglypuff.png" alt="" />
          </div>
          <div class="content">
            <div class="name">
              {{
                Ranking[2].username
                  ? overflowed(Ranking[2].username)
                  : overflowed("Anonymous")
              }}
            </div>
            <div class="earning">
              {{ TruncateToDecimals2(Ranking[2].totalBet || 0, "", 2) }}
              <img class="currency" src="@/assets/images/currency/bs.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="runnerup">
        <div v-for="(user, index) in RunnerUp" :key="index" class="user">
          <div class="index">
            {{ index + 4 }}
          </div>
          <img class="avatar" src="@/assets/images/mockup/avatar.png" alt="" />
          <div class="name">
            {{ user.username ? overflowed(user.username) : overflowed("Anonymous") }}
          </div>
          <div class="earning">
            {{ TruncateToDecimals2(user.totalBet || 0, "", 2) }}
            <img class="currency" src="@/assets/images/currency/bs.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
.footnote {
  color: #515151;
  text-align: center;
}
.leaderboard {
  padding: 20px;
  background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
  margin-top: 45px;
  .top {
    font-weight: 600;
    display: grid;
    grid-template-columns: 5fr 6fr 5fr;
    gap: 15px;
    padding-bottom: 20px;
    > div {
      text-align: center;
    }
    .first {
      grid-column: 2;
    }
    .second,
    .third {
      padding-top: 60px;
    }
    .tit {
      font-size: 16px;
      color: #b5b5b5;
      font-weight: 600;
    }
    .king {
      width: 20px
      // max-width: 15px;
    }
    .avatar {
      padding: 15px 0px;
      text-align: center;
      img {
        border-radius: 100vw;
        aspect-ratio: 1;
        border: 2px solid #c24e3f;
        padding: 5px;
        width: 100%;
        max-width: 100px;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  .runnerup {
    font-weight: 600;
    .user {
      border-top: 1px solid rgba(93, 184, 251, 0.42);
      padding: 5px 0px;
      display: flex;
      align-items: center;
      gap: 15px;
      position: relative;
      .index {
        color: #c3bfbf;
        position: absolute;
        left: 0;
      }
      .avatar {
        border-radius: 100vw;
        aspect-ratio: 1;
        border: 2px solid #515151;
        padding: 5px;
        width: 100%;
        max-width: 60px;
        margin-left: 30px;
        object-fit: cover;
      }
      .earning {
        flex-grow: 1;
        text-align: right;
      }
    }
  }
  .name {
    font-size: 14px;
    color: #c3bfbf;
    text-transform: uppercase;
  }
  .currency {
    max-width: 20px;
  }
  .earning {
    font-size: 12px;
  }

  @media (min-width: 576px) {
    .top {
      .avatar {
        padding: 15px 20px;
        img {
          max-width: 150px;
        }
      }
    }
    .runnerup {
      .user {
        padding: 15px 0px;
      }
    }
    .earning {
      font-size: 14px;
    }
    .name {
      font-size: 16px;
    }
  }
  @media (min-width: 992px) {
    .top {
      .avatar {
        img {
          max-width: 100px;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .name {
      font-size: 20px;
    }
    .earning {
      font-size: 18px;
    }
  }
}
</style>
