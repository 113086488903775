<template>
	<Loader v-if="isLoad" />
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapMutations } from 'vuex';

// eslint-disable-next-line no-undef
const axios = require('@/store/config/axios.config.js').default;
// eslint-disable-next-line no-undef
const axiosNodeJs = require('@/store/config/axiosNode.config.js').default;

export default {
	components: {
		Loader,
	},
	data() {
		return {
			isLoad: true,
		};
	},
	methods: {
		...mapMutations(['onload', 'outload']),
		checkParameters() {
			const token = this.$route.query.t;
			const tokenNodeJs = this.$route.query.tnoderjs;
			if (!tokenNodeJs || !token) {
				this.$router.push({ name: '404' });
			} else {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
				axiosNodeJs.defaults.headers.common['Authorization'] = 'Bearer ' + tokenNodeJs;
				this.$store.commit('auth/LOGIN_SUCCESS', {
					Bearer: token,
					BearerNodeJS: tokenNodeJs,
				});
				this.$router.push({ name: 'dashboard' });
			}
		},
	},
	created() {
		this.checkParameters();
	},
};
</script>

<style>
</style>