<template>
  <div class="action-wrapper">
    <div class="game-mode-tabs">
      <div class="tabs-wrapper">
        <div
          class="classic gradient-border withBG"
          :class="{ active: currentMode === 'classic' }"
          @click="currentMode = 'classic'"
          @keypress="currentMode = 'classic'"
        >
          Classic
        </div>
        <div
          class="trend gradient-border withBG"
          :class="{ active: currentMode === 'trend' }"
          @click="currentMode = 'trend'"
          @keypress="currentMode = 'trend'"
        >
          Trendball
        </div>
      </div>
    </div>
    <div class="manual" v-if="false">
      <button
        class="botan gradient-border withBG bet withBlur"
        @click="emitSetStopPoint"
        style="height: 35px; padding: 0px 20px; margin-right: 10px"
      >
        SET STOP POINT
      </button>
      <input type="number" v-model="amountStopPoint" />
    </div>
    <!-- Bet Classic -->
    <div v-if="currentMode === 'classic'" class="classic actions">
      <div class="interface">
        <div v-if="currentAction === 'manual'" class="manual">
          <!-- :class="isEnableAuto ? 'auto' : ''" -->
          <template>
            <template v-if="!isBet && !upgradeStatus">
              <button
                class="botan gradient-border withBG bet withBlur"
                @click="emitBet('classic')"
                v-if="GameStatus === 'PENDING'"
                :disabled="isEnableAuto"
              >
                WOW
              </button>
              <template v-else>
                <button
                  class="botan gradient-border withBG bet withBlur"
                  v-if="!isBetBeforeStart"
                  @click="isBetBeforeStart = true"
                  :disabled="isEnableAuto || isCheckGameEnd"
                >
                  WOW <br />
                  ({{ $t('happy.nextRound') }})
                </button>
                <button
                  class="botan red gradient-border withBG bet withBlur"
                  v-else
                  @click="isBetBeforeStart = false"
                  :disabled="isEnableAuto"
                >
                  {{ $t('happy.loading') }}...<br />
                  ({{ $t('profile.cancel') }})
                </button>
              </template>
            </template>
            <template v-else>
              <button
                class="botan gradient-border green-border withBG bet withBlur"
                v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                @click="emitBet('classic', true)"
                :disabled="isEnableAuto"
              >
                {{ TruncateToDecimals2(+amountBetUpgrade, '', 2) }}$
                <br />
                {{ $t('stake.upgrade') }}
              </button>
              <button
                v-else
                class="botan gradient-border green-border withBG bet withBlur"
                @click="onStop"
              >
                ${{
                  TruncateToDecimals2(
                    amountBetUpgrade * CounterChart || 0,
                    '',
                    2
                  )
                }}
                <br />
                {{ $t('happy.cashOut') }}
              </button>
            </template>
          </template>
          <div class="row text-left">
            <div class="col-md-6 col-lg-12 col-xl-6">
              <label for="bet-amount">
                <div class="pre-text">
                  <div>
                    {{ $t('wallet.amount') }}
                    <img src="@/assets/images/icons/stockUp.svg" alt="" />
                  </div>
                  <div class="min-bet">{{ TypeBalanceSelected }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{
                    disabled: isBet || isBetBeforeStart || isEnableAuto,
                  }"
                >
                  <img
                    class="currency"
                    :src="
                      require(`@/assets/images/currency/${TypeBalanceSelected.toLowerCase()}.png`)
                    "
                    alt=""
                  />
                  <input
                    type="number"
                    class="input"
                    id="bet-amount"
                    v-model="formBet.amountBet"
                    step="1.00"
                    onwheel="this.blur()"
                    :disabled="isBet || isBetBeforeStart || isEnableAuto"
                  />
                  <!-- formBet.amountBet = +TruncateToDecimals2(
                        +BalanceSocket[TypeBalanceSelected],
                        '',
                        2
                      ) -->
                  <button
                    :disabled="isBet || isBetBeforeStart || isEnableAuto"
                    class="botan lesser"
                    @click="
                      formBet.amountBet = BalanceSocket[TypeBalanceSelected]
                    "
                  >
                    {{ $t('happy.allIn') }}
                  </button>
                  <button
                    :disabled="isBet || isBetBeforeStart || isEnableAuto"
                    class="botan"
                    @click="predictAmountBet('/2')"
                  >
                    /2
                  </button>
                  <button
                    :disabled="isBet || isBetBeforeStart || isEnableAuto"
                    class="botan"
                    @click="predictAmountBet('x2')"
                  >
                    x2
                  </button>
                  <div class="number-roller">
                    <button
                      :disabled="isBet || isBetBeforeStart || isEnableAuto"
                      class="botan"
                      @click="predictAmountBet('add')"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-up.svg"
                        alt="up"
                      />
                    </button>
                    <button
                      :disabled="isBet || isBetBeforeStart || isEnableAuto"
                      class="botan"
                      @click="predictAmountBet('subtract')"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-down.svg"
                        alt="down"
                      />
                    </button>
                  </div>
                </div>
              </label>
            </div>
            <div class="col-md-6 col-lg-12 col-xl-6">
              <label for="bet-payout">
                <div class="pre-text">
                  <div class="auto-cash">{{ $t('happy.autoCashOut') }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{
                    disabled: isBet || isBetBeforeStart || isEnableAuto,
                  }"
                >
                  <input
                    class="input"
                    id="bet-payout"
                    type="number"
                    v-model="formBet.payout"
                    min="1.01"
                    step="0.0001"
                    @focusout="preventOne"
                    onwheel="this.blur()"
                    :disabled="isBet || isBetBeforeStart || isEnableAuto"
                  />
                  <button
                    :disabled="isBetBeforeStart"
                    class="botan auto"
                    :class="isEnableAuto ? 'auto-running' : ''"
                    @click="$bvModal.show('confirm-auto')"
                  >
                    {{
                      isEnableAuto ? $t('happy.disable') : $t('happy.enable')
                    }}
                    {{ $t('happy.auto') }}
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bet Trendball -->
    <div v-if="false" class="trend actions">
      <div class="interface">
        <div v-if="currentAction === 'manual'" class="manual">
          <div class="row text-left">
            <div class="col-12">
              <label for="bet-amount">
                <div class="pre-text">
                  <div>
                    {{ $t('wallet.amount') }}
                    <img src="@/assets/images/icons/stockUp.svg" alt="" />
                  </div>
                  <div class="min-bet">{{ TypeBalanceSelected }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{
                    disabled:
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon,
                  }"
                >
                  <img
                    class="currency"
                    :src="
                      require(`@/assets/images/currency/${TypeBalanceSelected.toLowerCase()}.png`)
                    "
                    alt=""
                  />
                  <input
                    class="input"
                    type="number"
                    id="bet-amount"
                    v-model="formBetTrend.amountBet"
                    onwheel="this.blur()"
                    :disabled="
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon
                    "
                  />
                  <button
                    :disabled="
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon
                    "
                    class="botan lesser"
                    @click="
                      formBetTrend.amountBet =
                        BalanceSocket[TypeBalanceSelected]
                    "
                  >
                    ALL IN
                  </button>
                  <button
                    class="botan"
                    @click="predictAmountBet('/2', true)"
                    :disabled="GameStatus !== 'PENDING'"
                  >
                    /2
                  </button>
                  <button
                    class="botan"
                    @click="predictAmountBet('x2', true)"
                    :disabled="GameStatus !== 'PENDING'"
                  >
                    x2
                  </button>
                  <div class="number-roller">
                    <button
                      class="botan"
                      @click="predictAmountBet('add', true)"
                      :disabled="GameStatus !== 'PENDING'"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-up.svg"
                        alt="up"
                      />
                    </button>
                    <button
                      class="botan"
                      @click="predictAmountBet('subtract', true)"
                      :disabled="GameStatus !== 'PENDING'"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-down.svg"
                        alt="up"
                      />
                    </button>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="bet-options">
            <!-- Bet Red -->
            <div class="option red">
              {{ $t('dashboard.payout') }} 1,76x
              <template v-if="!isBetRed">
                <template
                  v-if="GameStatus === 'START' && !isBetGreen && !isBetMoon"
                >
                  <button
                    class="botan"
                    v-if="!isBetBetTrendballRedBeforeStart"
                    @click="isBetBetTrendballRedBeforeStart = true"
                    :disabled="
                      isBetBetTrendballGreenBeforeStart ||
                      isBetBetTrendballMoonBeforeStart
                    "
                  >
                    <img src="@/assets/images/icons/strawberry.png" />
                    WOW Strawberry
                  </button>
                  <button
                    class="botan red"
                    v-else
                    @click="isBetBetTrendballRedBeforeStart = false"
                  >
                    <img src="@/assets/images/icons/strawberry.png" />
                    LOADING... / (CANCEL)
                  </button>
                </template>
                <button class="botan" @click="emitBet('red')" v-else>
                  <img src="@/assets/images/icons/strawberry.png" />
                  WOW Strawberry
                </button>
              </template>
              <template v-else>
                <button
                  class="botan red"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('red', true)"
                >
                  <img src="@/assets/images/icons/strawberry.png" />
                  Upgrade
                  {{ TruncateToDecimals2(+amountBetUpgradeTrendball, '', 2) }}$
                </button>
                <button v-else class="botan red">Wowing</button>
              </template>
            </div>
            <!-- Bet Green -->
            <div class="option green">
              {{ $t('dashboard.payout') }} 2x
              <template v-if="!isBetGreen">
                <template
                  v-if="GameStatus === 'START' && !isBetRed && !isBetMoon"
                >
                  <button
                    class="botan"
                    v-if="!isBetBetTrendballGreenBeforeStart"
                    @click="isBetBetTrendballGreenBeforeStart = true"
                    :disabled="
                      isBetBetTrendballRedBeforeStart ||
                      isBetBetTrendballMoonBeforeStart
                    "
                  >
                    <img src="@/assets/images/icons/lime.png" />
                    WOW Guava
                  </button>
                  <button
                    class="botan red"
                    v-else
                    @click="isBetBetTrendballGreenBeforeStart = false"
                  >
                    <img src="@/assets/images/icons/lime.png" />
                    LOADING... / (CANCEL)
                  </button>
                </template>
                <button
                  class="botan"
                  @click="emitBet('green')"
                  v-else
                  :disabled="GameStatus !== 'PENDING' || isBetRed || isBetMoon"
                >
                  <img src="@/assets/images/icons/lime.png" />
                  WOW Guava
                </button>
              </template>
              <template v-else>
                <button
                  class="botan green"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('green', true)"
                >
                  Upgrade
                  {{ TruncateToDecimals2(+amountBetUpgradeTrendball, '', 2) }}$
                </button>
                <button v-else class="botan green">Wowing</button>
              </template>
            </div>
            <!-- Bet Moon -->
            <div class="option orange">
              {{ $t('dashboard.payout') }} 10x
              <template v-if="!isBetMoon">
                <template
                  v-if="GameStatus === 'START' && !isBetRed && !isBetMoon"
                >
                  <button
                    class="botan"
                    v-if="!isBetBetTrendballMoonBeforeStart"
                    @click="isBetBetTrendballMoonBeforeStart = true"
                    :disabled="
                      isBetBetTrendballRedBeforeStart ||
                      isBetBetTrendballGreenBeforeStart
                    "
                  >
                    <img src="@/assets/images/icons/lemon.png" />
                    WOW Lemon
                  </button>
                  <button
                    class="botan red"
                    v-else
                    @click="isBetBetTrendballMoonBeforeStart = false"
                  >
                    <img src="@/assets/images/icons/lime.png" />
                    LOADING... / (CANCEL)
                  </button>
                </template>
                <button
                  class="botan"
                  @click="emitBet('moon')"
                  v-else
                  :disabled="GameStatus !== 'PENDING' || isBetRed || isBetGreen"
                >
                  <img src="@/assets/images/icons/lemon.png" />
                  WOW Lemon
                </button>
              </template>
              <template v-else>
                <button
                  class="botan orange"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('moon', true)"
                >
                  Upgrade
                  {{ TruncateToDecimals2(+amountBetUpgradeTrendball, '', 2) }}$
                </button>
                <button v-else class="botan orange">Wowing</button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal confirm auto -->
    <b-modal id="confirm-auto" hide-footer hide-header>
      <div class="header-modal">
        <h3>
          {{ isEnableAuto ? $t('happy.disable') : $t('happy.enable') }}
          {{ $t('happy.auto') }}
        </h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('confirm-auto')"
          class="close"
        />
      </div>
      <p class="text-center mb-0 mt-3">
        {{ $t('happy.DoubleCheckTheDesired') }}
        <span class="font-weight-bold text-warning">
          {{ $t('happy.amountOf') }} wow ( {{ formBet.amountBet }}$ ) </span
        >{{ $t('happy.and') }}
        <span class="font-weight-bold text-warning"
          >{{ $t('happy.cashOutNormal') }} ( {{ formBet.payout }}x )</span
        >
        {{ $t('happy.before') }}
        <span class="font-weight-bold text-warning"
          >{{ isEnableAuto ? $t('happy.disabling') : $t('happy.enabling') }}
          {{ $t('happy.auto') }}</span
        >
      </p>
      <div class="form-group text-center d-flex justify-content-center mt-4">
        <b-button class="btnNoStyle btn-auto ml-0" @click="onEnableAuto">
          {{ isEnableAuto ? $t('happy.disable') : $t('happy.enable') }}
          {{ $t('happy.auto') }}
        </b-button>
      </div>
    </b-modal>
    <!-- End Modal confirm auto -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CrashActions',
  props: {
    GameStatus: {
      type: String,
      required: true,
    },
    CounterChart: {
      type: Number,
      required: true,
    },
    StopPoint: {
      type: Number,
      default: 0,
    },
    MessageUser: {
      type: Object,
    },
  },
  data() {
    return {
      controlResult: 0,
      controlsValue: 0,
      amountUpgrade: 1,
      amountBetUpgrade: 0,
      amountUpgradeTrendball: 1,
      amountBetUpgradeTrendball: 0,
      amountStopPoint: 0,
      formBet: {
        amountBet: 5,
        payout: 100,
        currency: '',
      },
      formBetTrend: {
        amountBet: 5,
        currency: '',
      },
      isBet: false,
      isBetRed: false,
      isBetGreen: false,
      isBetMoon: false,
      currentAction: 'manual',
      currentMode: 'classic',
      isCheckSpamBet: false,
      isBetBeforeStart: false,
      isBetBetTrendballRedBeforeStart: false,
      isBetBetTrendballGreenBeforeStart: false,
      isBetBetTrendballMoonBeforeStart: false,
      upgradeStatus: false,
      autoCashOut: false,
      isEnableAuto: false,
      isCheckGameEnd: false,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'userinfo/UserInfo',
      TypeBalanceSelected: 'socket/TypeBalanceSelected',
      BalanceSocket: 'socket/BalanceSocket',
    }),
  },
  methods: {
    onStop() {
      this.$emit('cashOut');
    },
    async emitBet(betType, upgrade) {
      if (this.isCheckSpamBet) {
        this.$vToastify.error('Too Fast', this.$t('dashboard.fail'));
        return;
      }
      this.isCheckSpamBet = true;
      if (betType === 'classic') {
        if (
          this.TypeBalanceSelected === 'BS' &&
          +this.formBet.amountBet > +this.BalanceSocket.BS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          this.isEnableAuto = false;
          return;
        }
        if (
          this.TypeBalanceSelected === 'SBS' &&
          +this.formBet.amountBet > +this.BalanceSocket.SBS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          this.isEnableAuto = false;
          return;
        }
        if (
          this.TypeBalanceSelected === 'DBS' &&
          +this.formBet.amountBet > +this.BalanceSocket.DBS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          this.isEnableAuto = false;
          return;
        }
        this.formBet.currency = this.TypeBalanceSelected;
        if (upgrade) {
          this.upgradeStatus = upgrade;
        } else {
          this.amountBetUpgrade = this.formBet.amountBet;
        }
        this.$emit('userBet', { betType, formBet: this.formBet });
      } else {
        if (
          this.TypeBalanceSelected === 'BS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.BS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          return;
        }
        if (
          this.TypeBalanceSelected === 'SBS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.SBS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          return;
        }
        if (
          this.TypeBalanceSelected === 'DBS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.DBS
        ) {
          this.$vToastify.error(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          await this.sleep(1000);
          this.isCheckSpamBet = false;
          return;
        }

        this.formBetTrend.currency = this.TypeBalanceSelected;
        this.amountBetUpgradeTrendball = this.formBetTrend.amountBet;
        if (upgrade) {
          this.amountUpgradeTrendball += 1;
          this.amountBetUpgradeTrendball =
            this.formBetTrend.amountBet * this.amountUpgradeTrendball;
        }
        // if (betType === 'red') {
        //   this.isBetRed = true;
        // }
        // if (betType === 'green') {
        //   this.isBetGreen = true;
        // }
        // if (betType === 'moon') {
        //   this.isBetMoon = true;
        // }
        this.$emit('userBet', { betType, formBet: this.formBetTrend });
      }
      await this.sleep(1000);
      this.isCheckSpamBet = false;
    },
    async emitSetStopPoint() {
      this.$emit('userSetStopPoint', +this.amountStopPoint);
    },
    predictAmountBet(type, onTrend) {
      if (onTrend) {
        if (type === '/2') {
          this.formBetTrend.amountBet /= 2;
          if (this.formBetTrend.amountBet < 1) {
            this.formBetTrend.amountBet = 1;
          }
        }
        if (type === 'x2') {
          this.formBetTrend.amountBet *= 2;
          if (this.formBetTrend.amountBet > +this.BalanceSocket) {
            this.formBetTrend.amountBet = +this.BalanceSocket;
          }
        }

        if (type === 'add') {
          this.formBetTrend.amountBet = +this.formBetTrend.amountBet + 1;
        }
        if (type === 'subtract') {
          this.formBetTrend.amountBet = this.formBetTrend.amountBet - 1;
        }
      } else {
        if (type === '/2') {
          this.formBet.amountBet /= 2;
          if (this.formBet.amountBet < 1) {
            this.formBet.amountBet = 1;
          }
        }
        if (type === 'x2') {
          this.formBet.amountBet *= 2;
          if (this.formBet.amountBet > +this.BalanceSocket) {
            this.formBet.amountBet = +this.BalanceSocket;
          }
        }

        if (type === 'add') {
          this.formBet.amountBet = +this.formBet.amountBet + 1;
        }
        if (type === 'subtract') {
          this.formBet.amountBet = this.formBet.amountBet - 1;
        }
      }
    },
    preventOne(event) {
      const target = event.target;
      if (target.value <= 1 || target.value === '1') {
        this.formBet.payout = 1.01;
      }
    },
    setControlValue() {
      this.$emit('adminControls', +this.controlsValue);
    },
    setControlsTrend(input) {
      this.$emit('adminControls', input);
    },
    onEnableAuto() {
      this.isEnableAuto = !this.isEnableAuto;
      this.$bvModal.hide('confirm-auto');
    },
  },
  watch: {
    GameStatus: {
      async handler(newVal) {
        if (newVal === 'END') {
          this.amountUpgrade = 1;
          this.amountUpgradeTrendball = 1;
          this.upgradeStatus = false;

          this.isCheckGameEnd = true;
        }
        if (newVal === 'PENDING') {
          if (this.isBetBeforeStart) {
            this.emitBet('classic');
          }
          if (this.isBetBetTrendballRedBeforeStart) {
            this.emitBet('red');
          }
          if (this.isBetBetTrendballGreenBeforeStart) {
            this.emitBet('green');
          }
          if (this.isBetBetTrendballMoonBeforeStart) {
            this.emitBet('moon');
          }
          this.isBetBeforeStart = false;
          this.isBetBetTrendballRedBeforeStart = false;
          this.isBetBetTrendballGreenBeforeStart = false;
          this.isBetBetTrendballMoonBeforeStart = false;
          if (this.isEnableAuto) {
            await this.sleep(1000);
            this.emitBet('classic');
          }
          await this.sleep(1000);
          this.isCheckGameEnd = false;
        }
      },
    },
    isBetBeforeStart: {
      handler(newVal) {
        if (newVal === true) {
          if (this.GameStatus === 'PENDING') {
            this.emitBet('classic');
          }
        }
      },
    },
    isBetBetTrendballRedBeforeStart: {
      handler(newVal) {
        if (newVal === true) {
          if (this.GameStatus === 'END') {
            this.emitBet('red');
          }
        }
      },
    },
    isBetBetTrendballGreenBeforeStart: {
      handler(newVal) {
        if (newVal === true) {
          if (this.GameStatus === 'END') {
            this.emitBet('green');
          }
        }
      },
    },
    isBetBetTrendballMoonBeforeStart: {
      handler(newVal) {
        if (newVal === true) {
          if (this.GameStatus === 'END') {
            this.emitBet('red');
          }
        }
      },
    },
    MessageUser: {
      handler(newVal) {
        if (newVal) {
          // console.log(newVal);
          if (newVal && newVal.status) {
            if (
              newVal.action === 'STOP_BET' ||
              newVal.action === 'Payout Win'
            ) {
              this.upgradeStatus = false;
            }
            if (this.upgradeStatus) {
              this.amountUpgrade += 1;
              this.amountBetUpgrade =
                this.formBet.amountBet * this.amountUpgrade;
            }
            if (newVal.action === 'STOP_BET' || newVal.action === 'BET') {
              this.isBetBeforeStart = false;
            }
          } else {
            this.amountUpgrade = 1;
            this.isBetBeforeStart = false;
          }
        }
      },
    },
    isEnableAuto: {
      handler(newVal) {
        if (newVal === true) {
          if (this.GameStatus === 'PENDING') {
            if (this.isEnableAuto && !this.isBet) {
              this.emitBet('classic');
            }
          }
        }
      },
    },
    formBet: {
      handler(newVal) {
        if (newVal) {
          if (newVal.amountBet < 0) {
            this.formBet.amountBet = 1;
            return;
          }
          if (newVal.payout < 0) {
            this.formBet.payout = 1;
            return;
          }
          if (newVal.amountBet) {
            const amount = +newVal.amountBet;
            const amountSplit = amount.toString().split('.');
            if (amountSplit[1] && amountSplit[1].length > 2) {
              this.formBet.amountBet = +`${amountSplit[0]}${
                amountSplit[1] ? `.${amountSplit[1].slice(0, 2)}` : ''
              }`;
            }
          }
          if (newVal.payout) {
            const payout = +newVal.payout;
            const payoutSplit = payout.toString().split('.');
            if (payoutSplit[1] && payoutSplit[1].length > 2) {
              this.formBet.payout = +`${payoutSplit[0]}${
                payoutSplit[1] ? `.${payoutSplit[1].slice(0, 2)}` : ''
              }`;
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.action-wrapper {
  padding: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: column-reverse;
  .game-mode-tabs {
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    .tabs-wrapper {
      display: flex;
      margin-top: 15px;
      margin-bottom: 0px;
      > div {
        padding: 5px 15px;
        cursor: pointer;
      }
    }
    @media (min-width: 992px) {
      justify-content: flex-end;
    }
  }
  .actions {
    color: white;
    display: grid;
    grid-template-columns: auto;
    .tabs {
      display: flex;
      writing-mode: vertical-rl;
      // text-orientation: sideways-right;
      font-weight: 700;
      font-size: 12px;
      > div {
        padding: 30px 5px;
        transform: rotate(180deg);
        cursor: pointer;
        transition: 0.4s;
        flex: 1;
        text-align: center;
        &.gradient-border {
          &.active {
            --gradient: linear-gradient(#0ec5ff 1.08%, #3964fb 83%);
          }
        }
      }
      @media (min-width: 768px) {
        font-size: 14px;
        > div {
          padding: 30px 10px;
        }
      }
    }
    .interface {
      padding: 15px 0px;
      .manual {
        text-align: center;
        .gradient-border {
          &.red {
            --gradient: linear-gradient(#ff0e0e 1.08%, #fb3939 83%);
          }
        }
        .bet {
          max-width: 316px;
          width: 60%;
          margin-bottom: 30px;
          padding: 10px 15px;
          line-height: 1em;
          font-weight: 700;
          min-height: 55px;
          @media (min-width: 768px) {
            padding: 15px;
            width: 100%;
          }
          transition: all 0.4s, transform 0.1s;
          &:active {
            transform: scale(0.98);
          }
        }
        .login {
          grid-column: 2;
          margin-top: 10px;
        }
        label {
          width: 100%;
          font-size: 14px;
          padding: 10px 0px;
          .pre-text {
            display: flex;
            justify-content: space-between;
            padding: 0px 10px;
            .min-bet {
              color: #d3def4;
            }
            .auto-cash {
              color: rgba(255, 255, 255, 0.4);
            }
            .auto-chance {
              color: rgba(255, 255, 255, 0.4);
              b {
                color: #00c2ff;
              }
            }
          }
          .input-box {
            display: flex;
            padding: 4px;
            transition: 0.4s;
            &.disabled {
              background-color: rgb(40, 44, 52);
              > * {
                filter: grayscale(0.8);
              }
            }
            .currency {
              width: 19px;
              aspect-ratio: 1;
              object-fit: contain;
            }
            .input {
              font-size: 16px;
              padding: 0px 10px;
              font-weight: 600;
            }
            .botan {
              background: #495a88;
              border-radius: 2px;
              padding: 8px 18px;
              margin-right: 2px;
              transition: 0.2s;
              color: #99a4b0;
              white-space: nowrap;
              &.cancel {
                background: transparent;
              }
              &:hover {
                background: #5b70a9;
              }
              &.lesser {
                padding: 8px;
              }
              &.auto-running {
                position: relative;
                z-index: 1;
                color: #ffffff !important;
                filter: none !important;
                &::before {
                  content: '';
                  background: linear-gradient(
                    45deg,
                    #ff0000,
                    #ff7300,
                    #fffb00,
                    #48ff00,
                    #00ffd5,
                    #002bff,
                    #7a00ff,
                    #ff00c8,
                    #ff0000
                  );
                  position: absolute;
                  top: -2px;
                  left: -2px;
                  background-size: 400%;
                  z-index: -1;
                  filter: blur(5px);
                  width: calc(100% + 4px);
                  height: calc(100% + 4px);
                  animation: glowing 20s linear infinite;
                  opacity: 1;
                  transition: opacity 0.3s ease-in-out;
                  border-radius: 10px;
                }
                &::after {
                  z-index: -1;
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: #495a88;
                  color: #ffffff !important;
                  left: 0;
                  top: 0;
                  border-radius: 10px;
                  filter: grayscale(0) !important;
                }
              }
              &.auto {
                background: linear-gradient(
                  91.46deg,
                  #0ec5ff 1.08%,
                  #3964fb 83%
                );
                color: #ffffff;
              }
            }
            .number-roller {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .botan {
                padding: 0px 18px;
                line-height: 0;
                flex-grow: 1;
                &:first-child {
                  border-radius: 2px 2px 0px 0px;
                  img {
                    vertical-align: bottom;
                  }
                }
                &:last-child {
                  border-radius: 0px 0px 2px 2px;
                  img {
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }
      }

      .bet-options {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 10px;
        padding-top: 10px;
        .option {
          text-align: left;
          .botan {
            text-align: center;
            display: block;
            position: relative;
            background-color: #1b2c57;
            padding: 15px;
            width: 100%;
            border-radius: 4px;
            line-height: 1em;
            font-weight: 700;
            transition: 0.4s, transform 0.1s;
            &.red {
              background-color: red;
            }
            &.green {
              background-color: green;
            }
            &.orange {
              background-color: orange;
            }
            &:hover {
              filter: brightness(1.2);
            }
            &:disabled {
              filter: grayscale(80%);
              cursor: not-allowed;
            }
            &:active {
              transform: scale(0.98);
            }
          }
          img {
            width: 23px;
            aspect-ratio: 1;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
          }
        }
        @media (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      @media (min-width: 992px) {
        padding: 15px 10px;
      }
    }
  }
  @media (min-width: 992px) {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
#confirm-auto {
  p {
    font-size: 19px;
  }
  .btn-auto {
    margin-left: 15px;
    letter-spacing: 0.05rem;
    position: relative;
    padding: 10px 30px;
    background: linear-gradient(91.46deg, #ffb70e 1.08%, #fb8739 83%);
    border-radius: 6px;
    color: #fff !important;
    transition: 0.3s ease-in-out;
    z-index: 1;
    border: 0;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    @media (max-width: 576px) {
      padding: 5px 20px;
      font-size: 14px;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 25px;
      background: linear-gradient(91.46deg, #ffb70e 1.08%, #fb8739 83%);
      filter: blur(21px);
      z-index: -1;
    }
  }
}
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
