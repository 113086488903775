<template>
  <div class="crash-game-page">
    <div class="crash-game-wrapper">
      <div class="crash-game">
        <GameBoard
          @userBet="handleBet"
          :Countdown="gameCountDown2"
          :GameStatus="gameStatus2"
          :GameResult="gameResult"
          :GamePool="gamePoolSize2"
          :GameHistory="gameHistory2"
          :UserBet="UserBet2"
          :TotalCountdown="totalCountdown"
        />
      </div>
      <div class="crash-table">
        <GameLeaderboard :Ranking="leaderboard"></GameLeaderboard>
      </div>
    </div>
    <div class="pt-5">
      <GameHistory
        v-if="UserBetHistory2"
        :HistoryBetUser="UserBetHistory2"
        :HistorySystem="gameHistory2"
      ></GameHistory>
    </div>
    <div class="provider">
      {{ $t("happy.aboutTheProvider") }}
      <div class="image-wrapper">
        <img src="@/assets/images/logo-full.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import { mapGetters, mapMutations } from "vuex";
import GameBoard from "../components/CrashTwo/GameBoard.vue";
import GameHistory from "../components/CrashTwo/GameHistory.vue";
import GameLeaderboard from "../components/CrashTwo/GameLeaderboard.vue";

export default {
  name: "CrashPage",
  components: {
    GameBoard,
    GameHistory,
    GameLeaderboard,
  },
  data() {
    return {
      // define socket data
      socket: null,
      socketStatus: null,

      messageUser: null,

      // Game two
      gameCountDown2: 0,
      gameStatus2: "CONNECTING",
      gamePoolSize2: 0,
      gameResult: 0,
      UserBet2: {
        greenBet: 0,
        redBet: 0,
        yellowBet: 0,
        currency: null,
      },
      AllUserBet2: {},
      HistoryBetUser2: {},
      gameHistory2: [],
      totalCountdown: 0,
      leaderboard: [],

      damper: false,
    };
  },
  computed: {
    ...mapGetters({
      TypeBalanceSelected: "socket/TypeBalanceSelected",
      CurrentTab: "socket/CurrentTab",
      IsRefreshDemo: "socket/IsRefreshDemo",
      IsNetWork: "core/IsNetWork",
    }),
    UserBetHistory2() {
      if (this.HistoryBetUser2) {
        switch (this.CurrentTab) {
          case "userBet":
            return this.HistoryBetUser2.user_history_2;
          case "userBetMain":
            return this.HistoryBetUser2.user_history_main_2;
          case "userBetStaking":
            return this.HistoryBetUser2.user_history_staking_2;
        }
      }
      return {};
    },
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    async connectWSSToken(tokens) {
      let self = this;
      // VUE_APP_SOCKET_BETA
      // VUE_APP_SOCKET_MAIN
      // eslint-disable-next-line no-undef
      this.socket = await io(process.env.VUE_APP_SOCKET_BETA, {
        reconnectionDelayMax: 10000,
        reconnection: false,
        query: {
          token: tokens,
          lang: this.$i18n.locale,
          chooseGame: 2,
        },
      });

      this.socket.on("private-message", (data) => {
        if (data.action === "SUCCESS") {
          this.$vToastify.success(data.message, "Congratulations !");
        } else {
          this.$vToastify.error("", data.message);
        }
      });

      this.socket.on("GET_BALANCE", (data) => {
        this.tempBalance = data;
        this.$store.commit("socket/SET_BALANCE", data);
      });

      this.socket.on("MESSAGE", (data) => {
        this.messageUser = data;
        if (data.status) {
          if (data.action === "RAW") {
            this.$vToastify.warning(data.message, "Success!");
          } else {
            this.$vToastify.success(data.message, "Success!");
          }
        } else {
          this.$vToastify.error("", data.message);
        }
        if (data.action === "SUCCESS") {
          const myMessage =
            this.TruncateToDecimals2(data.profit, "", 2) + " " + data.currency;

          console.log(data);
          this.$store.commit("socket/SET_POP_TOAST", { message: myMessage });
          this.$bvModal.show("notiPop");
          clearTimeout(this.myTimeout);

          this.myTimeout = setTimeout(() => {
            this.$bvModal.hide("notiPop");
          }, 3000);
        }
      });

      // GAME TWO
      this.socket.on("COUNT_DOWN_2", (data) => {
        self.gameCountDown2 = data;
      });

      this.socket.on("CHANGE_STATUS_2", (data) => {
        this.gameStatus2 = data;
        if (data === "END") {
          self.UserBet2 = {
            ...self.UserBet2,
            greenBet: 0,
            redBet: 0,
            yellowBet: 0,
            currency: null,
          };
        }
      });

      this.socket.on("GET_RESULT_2", (data) => {
        this.gameResult = data;
      });

      this.socket.on("POOL_SIZE_2", (data) => {
        this.gamePoolSize2 = data;
      });

      this.socket.on("GET_ALL_USER_BET_2", () => {
        // this.AllUserBet2 = data;
      });
      this.socket.on("GET_BET_RECONNECT_2", (data) => {
        if (data) {
          if (data.resultBetGreen && data.resultBetGreen.length > 0) {
            this.UserBet2.greenBet = data.resultBetGreen[0].amountBet;
            this.UserBet2.currency = data.resultBetGreen[0].currency;
            this.$store.commit("socket/SET_POP_TOAST", {
              currency: this.UserBet2.currency,
              chara: "sonic",
            });
          }
          if (data.resultBetYellow && data.resultBetYellow.length > 0) {
            this.UserBet2.yellowBet = data.resultBetYellow[0].amountBet;
            this.UserBet2.currency = data.resultBetYellow[0].currency;
            this.$store.commit("socket/SET_POP_TOAST", {
              currency: this.UserBet2.currency,
              chara: "pikachu",
            });
          }
          if (data.resultBetRed && data.resultBetRed.length > 0) {
            this.UserBet2.redBet = data.resultBetRed[0].amountBet;
            this.UserBet2.currency = data.resultBetRed[0].currency;
            this.$store.commit("socket/SET_POP_TOAST", {
              currency: this.UserBet2.currency,
              chara: "jigglypuff",
            });
          }
        }
      });
      this.socket.on("HISTORY_USER_2", (data) => {
        this.HistoryBetUser2 = data;
      });
      this.socket.on("HISTORY_2", (data) => {
        this.gameHistory2 = data.data;
      });
      this.socket.on("TOTAL_COUNT_DOWN", (data) => {
        this.totalCountdown = data;
      });
      this.socket.on("GET_LEADER_BOARD", (data) => {
        this.leaderboard = data;
      });
    },

    onBetRed(formBet) {
      const bet = +formBet.amountBet;
      this.socket.emit("BET_RED_2", formBet, (res) => {
        if (res.status) {
          this.UserBet2.redBet += bet;
          this.UserBet2.currency = formBet.currency;

          this.$store.commit("socket/SET_POP_TOAST", {
            currency: this.UserBet2.currency,
            chara: "jigglypuff",
          });
          this.$vToastify.success(res.message, "Success!");
        } else {
          this.$vToastify.error(res.message, "Error!");
        }
      });
    },
    onBetGreen(formBet) {
      const bet = +formBet.amountBet;
      this.socket.emit("BET_GREEN_2", formBet, (res) => {
        if (res.status) {
          this.UserBet2.greenBet += bet;
          this.UserBet2.currency = formBet.currency;
          this.$store.commit("socket/SET_POP_TOAST", {
            currency: this.UserBet2.currency,
            chara: "sonic",
          });
          this.$vToastify.success(res.message, "Success!");
        } else {
          this.$vToastify.error(res.message, "Error!");
        }
      });
    },
    onBetYellow(formBet) {
      const bet = +formBet.amountBet;
      this.socket.emit("BET_YELLOW_2", formBet, (res) => {
        if (res.status) {
          this.UserBet2.yellowBet += bet;
          this.UserBet2.currency = formBet.currency;

          this.$store.commit("socket/SET_POP_TOAST", {
            currency: this.UserBet2.currency,
            chara: "pikachu",
          });
          this.$vToastify.success(res.message, "Success!");
        } else {
          this.$vToastify.error(res.message, "Error!");
        }
      });
    },

    handleBet({ betType, formBet }) {
      if (!this.damper) {
        switch (betType) {
          case "red":
            this.onBetRed(formBet);
            break;
          case "green":
            this.onBetGreen(formBet);
            break;
          case "yellow":
            this.onBetYellow(formBet);
        }
        this.damper = true;
        setTimeout(() => {
          this.damper = false;
        }, 1000);
      }
    },
  },
  watch: {
    IsRefreshDemo: {
      handler(newVal) {
        let self = this;
        if (newVal === "REFRESH") {
          setTimeout(() => {
            self.socket.emit("SET_BALANCE");
          }, 1000);
        }
      },
    },
  },
  async mounted() {
    const self = this;
    const check = setInterval(async () => {
      const gameChart = await self.$refs.gameChart;
      if (gameChart) {
        clearInterval(check);
        return;
      }
    });
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.outload();
    const tokenSocket = window.$cookies.get("socket_session");
    this.connectWSSToken(tokenSocket.key);
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "wallet/DEPOSIT_FROM_BALANCE_MAIN_TO_GAME_SUCCESS":
        case "wallet/DEPOSIT_FROM_BALANCE_STAKE_TO_GAME_SUCCESS":
        case "wallet/DEPOSIT_FROM_BALANCE_PROFIT_STAKE_TO_GAME_SUCCESS":
          this.socket.emit("SET_BALANCE");
          break;
      }
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
    this.unsubscribe();
    this.$store.commit("socket/SET_BALANCE", {});
  },
};
</script>

<style lang="scss" scoped>
.crash-game-page {
  .crash-game-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;

    .crash-game {
      background-image: url("~@/assets/images/starry.svg"),
        linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);

      // Animation
      background-size: 200% 200%, 100%;
      animation: bgPan 10s linear infinite;
      animation-play-state: paused;

      &.go {
        animation-play-state: running;
      }

      @keyframes bgPan {
        from {
          background-position: 0 0;
        }

        to {
          background-position: 200% -200%;
        }
      }

      ///////////////////////////
    }

    .table-header {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;

      .privacy-switch {
        height: 25px;
        display: inline-block;

        input[type="checkbox"] {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 50px;
          height: 25px;
          background: #1165948a;
          display: block;
          border-radius: 100px;
          position: relative;
          margin-top: -25px;
        }

        label:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          background: #656b7d;
          border-radius: 90px;
          transition: 0.3s;
        }

        input:checked + label {
          background: rgba(255, 203, 18, 0.1);
        }

        input:checked + label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
          background: #ffcb12;
        }

        label:active:after {
          width: 50px;
        }
      }
    }

    .crash-table {
      padding: 10px;
    }

    @media (min-width: 768px) {
      .crash-table {
        padding: 0;
      }

      .table-header {
        padding: 5px 0px;
      }
    }

    @media (min-width: 992px) {
      grid-template-columns: 8fr 4fr;

      .crash-game {
        grid-row: 2;
      }

      .crash-table {
        grid-row: 2;
      }

      .table-header {
        grid-row: 1;
        grid-column: 2;
        padding: 5px 0px;
      }
    }
  }

  .tabs-wrapper {
    display: inline-block;
    margin: 30px 10px;

    @media (min-width: 768px) {
      margin: 30px 0px;
    }

    .botan {
      padding: 10px 30px;
    }
  }

  .provider {
    padding: 10px;
    padding-top: 30px !important;
    font-weight: 600;

    .image-wrapper {
      margin-top: 30px;
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      padding: 20px;

      img {
        max-width: 100%;
      }
    }

    @media (min-width: 576px) {
      .image-wrapper {
        padding: 40px;
      }
    }

    @media (min-width: 768px) {
      padding: 0px;
    }
  }

  .rewardsPop {
    position: absolute;
    inset: 0;
    z-index: 100;
    background-color: #081e4334;
  }

  @media (min-width: 768px) {
    padding: 1rem;
  }

  @media (min-width: 1400px) {
    padding: 1rem 2rem;
  }
}
</style>
