var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trendBall"},[_c('div',{staticClass:"trendBall-content"},[_vm._m(0),_c('div',{staticClass:"trendBall-item"},[_c('div',{staticClass:"trendBall-item-header"},[_c('img',{attrs:{"src":"https://bc.game/assets/trenball_red.325f3400.png","alt":""}}),_c('h3',{staticClass:"redBear"},[_vm._v("Red Bear")]),_c('div',{staticClass:"redBear-item-total"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('happy.player')))]),_vm._v(" "+_vm._s(_vm.totalPlayerBetRed))]),_c('p',{staticClass:"d-flex align-items-center mb-0"},[_c('img',{staticClass:"mr-1 mb-0",staticStyle:{"width":"17px !important"},attrs:{"src":require("@/assets/images/icons/icon-usd.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(_vm.totalAmountBetRed, "", 2))+" ")])])]),_c('div',{staticClass:"trendBall-item-content"},[_c('p',[_vm._v(_vm._s(_vm.$t('happy.player')))]),_c('p',[_vm._v("Wow")])])]),_c('div',{staticClass:"trendBall-item"},[_c('div',{staticClass:"trendBall-item-header"},[_c('img',{attrs:{"src":"https://bc.game/assets/trenball_green.86008f7e.png","alt":""}}),_c('h3',{staticClass:"greenBull"},[_vm._v("Green Bull")]),_c('div',{staticClass:"greenBull-item-total"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('happy.player')))]),_vm._v(" "+_vm._s(_vm.totalPlayerBetGreen))]),_c('p',[_c('img',{staticClass:"mr-1 mb-0",staticStyle:{"width":"17px !important"},attrs:{"src":require("@/assets/images/icons/icon-usd.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(_vm.totalAmountBetGreen, "", 2))+" ")])])]),_c('div',{staticClass:"trendBall-item-content"},[_c('p',[_vm._v(_vm._s(_vm.$t('happy.player')))]),_c('p',[_vm._v("Wow")])])])]),(
      (_vm.AllBetRed && _vm.AllBetRed.length > 0) ||
      (_vm.AllBetGreen && _vm.AllBetGreen.length > 0)
    )?[_c('div',{staticClass:"trendBall-bet",attrs:{"id":"trendBall-bet"}},[_c('div',{staticClass:"trendBall-bet-list redBear"},_vm._l((_vm.AllBetRed),function(bet,idx){return _c('div',{key:'red' + idx,staticClass:"trendBall-bet-item"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/mockup/bet-list.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm._f("overflowed")(bet.username))+" ")]),_c('p',{class:_vm.StatusBetGreen === 'PENDING'
                ? 'text-white'
                : !_vm.StatusBetGreen
                ? 'text-win'
                : 'text-lose'},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(+bet.amountBet, "", 2))+" "),_c('img',{attrs:{"src":require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`),"alt":""}})])])}),0),_c('div',{staticClass:"trendBall-bet-list greenBull"},_vm._l((_vm.AllBetGreen),function(bet,idx){return _c('div',{key:'green' + idx,staticClass:"trendBall-bet-item"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/mockup/bet-list.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm._f("overflowed")(bet.username))+" ")]),_c('p',{class:_vm.StatusBetGreen === 'PENDING'
                ? 'text-white'
                : _vm.StatusBetGreen
                ? 'text-win'
                : 'text-lose'},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(+bet.amountBet, "", 2))+" "),_c('img',{attrs:{"src":require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`),"alt":""}})])])}),0)]),(false)?_c('div',{staticClass:"trendBall-button"},[_c('b-button',{staticClass:"btnNoStyle",on:{"click":_vm.changeStatusShowMore}},[_vm._v(" Show "+_vm._s(!_vm.showMore ? "More" : "Less")+" "),(!_vm.showMore)?_c('img',{attrs:{"src":require("@/assets/images/icons/icon-down.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/icons/icon-up.png"),"alt":""}})])],1):_vm._e()]:_c('p',{staticClass:"text-center mt-5 text-white font-weight-bold"},[_vm._v(" Nothing here ")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trendBall-vs"},[_c('img',{attrs:{"src":require("@/assets/images/versus.svg"),"alt":""}})])
}]

export { render, staticRenderFns }