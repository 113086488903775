import { render, staticRenderFns } from "./BetListedClassic.vue?vue&type=template&id=3be0c053&scoped=true"
import script from "./BetListedClassic.vue?vue&type=script&lang=js"
export * from "./BetListedClassic.vue?vue&type=script&lang=js"
import style0 from "./BetListedClassic.vue?vue&type=style&index=0&id=3be0c053&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be0c053",
  null
  
)

export default component.exports