<template>
  <div class="historyCrash">
    <h3>{{ $t("happy.latestWow") }}</h3>
    <div class="historyCrash-content">
      <div class="text-center text-lg-left">
        <div class="historyCrash-groupBtn tabs-wrapper">
          <button
            class="botan gradient-border withBG withBlur"
            :class="CurrentTab === 'userBet' ? 'active' : ''"
            @click="setTab('userBet')"
          >
            {{ $t("dashboard.myWowDemo") }}
          </button>
          <button
            class="botan gradient-border withBG withBlur"
            :class="CurrentTab === 'userBetMain' ? 'active' : ''"
            @click="setTab('userBetMain')"
          >
            {{ $t("dashboard.myWowBS") }}
          </button>
          <button
            class="botan gradient-border withBG withBlur"
            :class="CurrentTab === 'userBetStaking' ? 'active' : ''"
            @click="setTab('userBetStaking')"
          >
            {{ $t("dashboard.myWowStaking") }}
          </button>
          <button
            class="botan gradient-border withBG withBlur"
            :class="CurrentTab === 'history' ? 'active' : ''"
            @click="setTab('history')"
          >
            {{ $t("exchange.history") }}
          </button>
        </div>
      </div>
      <!-- History -->
      <template v-if="CurrentTab === 'history'">
        <b-table
          striped
          hover
          :items="HistoryShow"
          :fields="fieldsHistory"
          show-empty
          responsive
          class="myTable"
        >
          <template #empty>
            <h4 class="text-center my-3">
              {{ $t("support.no_data_available_in_table") }}
            </h4>
          </template>
          <template #cell(result)="data">
            <p
              class="mb-0 text-betting font-weight-bold"
              :class="
                +data.item.result >= 55
                  ? 'text-danger'
                  : +data.item.result >= 45
                  ? 'text-warning'
                  : 'text-success'
              "
            >
              {{ TruncateToDecimals2(data.item.result, "", 2) }}
            </p>
          </template>
          <template #cell(timeStart)="data">
            {{ getDateTime(data.item.timeStart) }}
          </template>
        </b-table>
        <div class="table-mobile">
          <div
            v-for="(history, idx) in HistoryShow"
            :key="'gameHistory: ' + idx"
            class="w-100"
            role="tablist"
          >
            <b-button
              v-b-toggle="`gameHistory-${idx}`"
              class="btnNoStyle header"
              role="tab"
              >Game result: {{ history.result }}x</b-button
            >
            <b-collapse
              :id="`gameHistory-${idx}`"
              class="mt-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <ul>
                <li>
                  Hash: <span>{{ history.hash }}</span>
                </li>
                <li>
                  {{ $t("happy.result") }}: <span>{{ history.result }}x</span>
                </li>
                <li>
                  {{ $t("wallet.time") }}: <span>{{ history.timeStart }}</span>
                </li>
              </ul>
            </b-collapse>
          </div>
        </div>
        <div class="d-flex justify-content-end w-100">
          <Paginate
            :totalPage="TotalPage"
            :perPage="page"
            @current="onChangePage"
          />
        </div>
      </template>
      <!-- History User -->
      <template v-else>
        <b-table
          striped
          hover
          :items="HistoryUserShow"
          :fields="fieldsHistoryUser"
          show-empty
          responsive
          class="myTable"
        >
          <template #empty>
            <h4 class="text-center my-3">
              {{ $t("support.no_data_available_in_table") }}
            </h4>
          </template>

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- <template #cell(payout)="data">
            <p
              v-if="data.item.payout"
              class="mb-0 text-betting font-weight-bold"
              :class="
                +data.item.payout >= 55
                  ? 'text-danger'
                  : +data.item.payout >= 45
                  ? 'text-warning'
                  : 'text-success'
              "
            >
              {{ TruncateToDecimals2(data.item.payout || 0, "", 2) }}
            </p>
          </template> -->

          <template #cell(amountBet)="data">
            <p class="mb-0 text-betting font-weight-bold">
              <img
                v-if="data.item.currency === 'BS'"
                src="@/assets/images/currency/bs.png"
                class="currency-icon"
              />
              <img
                v-if="data.item.currency === 'SBS'"
                src="@/assets/images/currency/sbs.png"
                class="currency-icon"
              />
              {{ TruncateToDecimals2(data.item.amountBet, "", 2) }}
            </p>
          </template>

          <template #cell(amountWin)="data">
            <p class="mb-0 text-betting font-weight-bold">
              <img
                v-if="data.item.currency === 'BS'"
                src="@/assets/images/currency/bs.png"
                class="currency-icon"
              />
              <img
                v-if="data.item.currency === 'SBS'"
                src="@/assets/images/currency/sbs.png"
                class="currency-icon"
              />
              {{ TruncateToDecimals2(data.item.amountWin, "", 2) }}
            </p>
          </template>

          <template #cell(timeBet)="data">
            {{ getDateTime(data.item.timeBet) }}
          </template>

          <template #cell(profit)="data">
            <template v-if="data.item.status === 'SUCCESS'">
              <p class="text-win mb-0 font-weight-bold">
                {{
                  TruncateToDecimals2(
                    data.item.amountWin - data.item.amountBet,
                    "",
                    2
                  )
                }}
              </p>
            </template>
            <template v-else-if="data.item.status === 'FAIL'">
              <p class="text-lose mb-0 font-weight-bold">
                -
                {{ TruncateToDecimals2(data.item.amountBet, "", 2) }}
              </p>
            </template>
            <template v-else>
              <p class="mb-0 font-weight-bold">0</p>
            </template>
          </template>
        </b-table>
        <div class="table-mobile">
          <div
            v-for="(history, idx) in HistoryUserShow"
            :key="'playHistory: ' + idx"
            class="w-100"
            role="tablist"
          >
            <b-button
              v-b-toggle="`playHistory-${idx}`"
              class="btnNoStyle header"
              :class="{ lost: history.status === 'FAIL' }"
              role="tab"
              >{{ idx + 1 }}. Wow {{ $t("wallet.amount") }}:
              {{ history.amountBet }}</b-button
            >
            <b-collapse
              :id="`playHistory-${idx}`"
              class="mt-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <ul>
                <li>
                  Wow: <span>{{ history.amountBet }}</span>
                </li>
                <li v-if="history.payout">
                  {{ $t("happy.result") }}:
                  <span>{{ history.payout }}</span>
                </li>
                <li>
                  Wow {{ $t("dashboard.success") }}:
                  <span>{{ history.amountWin }}</span>
                </li>
                <li>
                  {{ $t("exchange.profit") }}:
                  <span>
                    {{
                      TruncateToDecimals2(
                        history.amountWin - history.amountBet,
                        "",
                        2
                      )
                    }}
                  </span>
                </li>
                <li>
                  {{ $t("dashboard.typeBet") }}:
                  <span>
                    {{ history.typeBet }}
                  </span>
                </li>
                <li>
                  Hash: <span>{{ history.hash }}</span>
                </li>
                <li>
                  {{ $t("wallet.comment") }}: <span>{{ history.comment }}</span>
                </li>
                <li>
                  {{ $t("exchange.time") }}:
                  <span>{{ getDateTime(history.timeBet) }}</span>
                </li>
              </ul>
            </b-collapse>
          </div>
        </div>
        <div class="d-flex justify-content-end w-100">
          <Paginate
            :totalPage="TotalPageUser"
            :perPage="page"
            @current="onChangePageUser"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Paginate from "@/components/Shared/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "GameHistory",
  components: {
    Paginate,
  },
  props: {
    HistoryBetUser: {
      type: Object,
      required: true,
    },
    HistorySystem: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      CurrentTab: "socket/CurrentTab",
    }),

    fieldsHistoryUser() {
      return [
        {
          key: "index",
          label: "Index",
          class: "text-center min-200",
        },
        {
          key: "amountBet",
          sortable: false,
          label: "Wow",
          class: "text-center min-200",
        },
        // {
        //   key: "payout",
        //   sortable: false,
        //   label: this.$t("happy.result"),
        //   class: "text-center min-200",
        // },
        {
          key: "amountWin",
          sortable: false,
          label: "Wow " + this.$t("dashboard.success"),
          class: "text-center min-200",
        },
        {
          key: "profit",
          label: this.$t("exchange.profit"),
          class: "text-center",
        },
        {
          key: "typeBet",
          label: this.$t("dashboard.typeBet"),
          class: "text-center",
        },
        {
          key: "hash",
          sortable: false,
          label: "Hash",
          class: "text-center min-200 canWrap",
        },
        {
          key: "comment",
          label: this.$t("wallet.comment"),
          class: "text-center bigContent",
        },
        {
          key: "timeBet",
          label: this.$t("exchange.time"),
          class: "text-center",
        },
      ];
    },

    fieldsHistory() {
      return [
        {
          key: "hash",
          sortable: false,
          label: "Hash",
          class: "text-center min-200",
        },
        {
          key: "result",
          label: this.$t("happy.result"),
          sortable: false,
          class: "text-center text-capitalize",
        },
        {
          key: "timeStart",
          label: this.$t("wallet.time"),
          class: "text-center",
        },
      ];
    },

    TotalPage() {
      return (this.HistorySystem.length / 10).toFixed(0);
    },
    HistoryShow() {
      let arr = [];
      arr = this.HistorySystem.slice((this.page - 1) * 10, this.page * 10);
      return arr;
    },

    TotalPageUser() {
      let totalPage = 1;
      const length =
        this.HistoryBetUser && this.HistoryBetUser.results
          ? this.HistoryBetUser.results.length
          : 0;
      if (length > 0) {
        totalPage = length % 10;
        if (length <= 10) {
          totalPage = 1;
        } else if (totalPage > 0) {
          totalPage = Math.floor(length / 10) + 1;
        } else {
          totalPage = length / 10;
        }
      }
      return totalPage;
    },
    HistoryUserShow() {
      if (
        this.HistoryBetUser &&
        this.HistoryBetUser.results &&
        this.HistoryBetUser.results.length > 0
      ) {
        let arr = [];
        arr = this.HistoryBetUser.results.slice(
          (this.page - 1) * 10,
          this.page * 10
        );
        return arr;
      }
      return [];
    },
  },
  methods: {
    onChangePage(payload) {
      this.page = payload;
    },
    onChangePageUser(payload) {
      this.page = payload;
    },
    setTab(input) {
      this.page = 1;
      this.$store.commit("socket/SET_CURRENT_TAB", input);
    },
  },
  mounted() {
    this.setTab("userBetMain");
  },
};
</script>

<style lang="scss" scoped>
.historyCrash {
  padding: 0px 10px;
  @media (min-width: 768px) {
    padding: 0;
  }
  h3 {
    font-size: 16px;
    color: white;
  }
  .historyCrash-content {
    background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
    border-radius: 3px;
    padding: 20px 15px;
    margin-top: 40px;
    .historyCrash-groupBtn {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .botan {
        padding: 10px 30px;
        &.active {
          background: #1b2c57 !important;
          color: white;
        }
      }
    }
  }
  .currency-icon {
    width: 25px;
    object-fit: contain;
  }
}
.table-mobile {
  width: 100%;
  background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
  border-radius: 4px;
  padding: 20px;

  .header {
    width: 100%;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    border-radius: 6px;
    padding: 0px 25px;
    min-height: 55px;
    margin-bottom: 20px;
    font-weight: 700;
    &.lost {
      background: linear-gradient(91.46deg, #be1f2d 1.08%, #d7535e 83%);
    }
  }

  .collapse {
    margin-top: -20px !important;
    padding: 20px 10px;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(34px);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    margin-bottom: 20px;

    P {
      margin-bottom: 0px;
    }
    ul {
      li {
        font-weight: 700;
        span {
          word-wrap: break-word;
          font-weight: 400;
          color: rgb(219, 224, 235);
        }
      }
    }
  }
  @media (min-width: 576px) {
    display: none;
  }
}
.myTable {
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
}
</style>
