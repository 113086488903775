<template>
  <div class="description">
    <h3>{{ $t('happy.aboutThisGame') }}</h3>
    <p>
      {{ $t('happy.contentAboutGame') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DescriptionPageView',
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.description {
  padding: 0px 10px;
  @media (min-width: 768px) {
    padding: 0;
  }
  h3 {
    font-size: 16px;
    color: white;
  }
  p {
    color: #6a6a6a;
    font-size: 14px;
    margin-top: 30px;
  }
}
</style>
