<template>
  <div class="action-wrapper">
    <div class="game-mode-tabs">
      <div class="tabs-wrapper">
        <div
          class="classic gradient-border withBG"
          :class="{ active: currentMode === 'classic' }"
          @click="currentMode = 'classic'"
          @keypress="currentMode = 'classic'"
        >
          Classic
        </div>
        <div
          class="trend gradient-border withBG"
          :class="{ active: currentMode === 'trend' }"
          @click="currentMode = 'trend'"
          @keypress="currentMode = 'trend'"
        >
          Trendball
        </div>
      </div>
    </div>
    <div v-if="currentMode === 'classic'" class="classic actions">
      <div class="interface">
        <div v-if="currentAction === 'manual'" class="manual">
          <template>
            <button
              class="botan gradient-border withBG bet withBlur"
              @click="emitBet('classic')"
              :disabled="GameStatus !== 'PENDING'"
              v-if="!isBet"
            >
              WOW
            </button>
            <template v-else>
              <!-- <button
                class="botan gradient-border green-border withBG bet withBlur"
                v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                @click="emitBet('classic', true)"
              >
                Upgrade
              </button> -->
              <button
                class="botan gradient-border green-border withBG bet withBlur"
                v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
              >
                {{ $t('happy.loading') }}
              </button>
              <button
                v-else
                class="botan gradient-border green-border withBG bet withBlur"
                @click="onStop"
              >
                ${{
                  TruncateToDecimals2(
                    formBet.amountBet * CounterChart || 0,
                    '',
                    2
                  )
                }}
                <br />
                {{ $t('happy.cashOut') }}
              </button>
            </template>
          </template>
          <div class="row text-left">
            <div class="col-md-6 col-lg-12 col-xl-6">
              <label for="bet-amount">
                <div class="pre-text">
                  <div>
                    {{ $t('wallet.amount') }}
                    <img src="@/assets/images/icons/stockUp.svg" alt="" />
                  </div>
                  <div class="min-bet">{{ TypeBalanceSelected }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{ disabled: GameStatus !== 'PENDING' || isBet }"
                >
                  <img
                    class="currency"
                    :src="
                      require(`@/assets/images/currency/${TypeBalanceSelected.toLowerCase()}.png`)
                    "
                    alt=""
                  />
                  <input
                    type="number"
                    class="input"
                    id="bet-amount"
                    v-model="formBet.amountBet"
                    onwheel="this.blur()"
                    :disabled="GameStatus !== 'PENDING' || isBet"
                  />
                  <button
                    :disabled="GameStatus !== 'PENDING' || isBet"
                    class="botan lesser"
                    @click="
                      formBet.amountBet = BalanceSocket[TypeBalanceSelected]
                    "
                  >
                    ALL IN
                  </button>
                  <button
                    :disabled="GameStatus !== 'PENDING' || isBet"
                    class="botan"
                    @click="predictAmountBet('/2')"
                  >
                    /2
                  </button>
                  <button
                    :disabled="GameStatus !== 'PENDING' || isBet"
                    class="botan"
                    @click="predictAmountBet('x2')"
                  >
                    x2
                  </button>
                  <div class="number-roller">
                    <button
                      :disabled="GameStatus !== 'PENDING' || isBet"
                      class="botan"
                      @click="predictAmountBet('add')"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-up.svg"
                        alt="up"
                      />
                    </button>
                    <button
                      :disabled="GameStatus !== 'PENDING' || isBet"
                      class="botan"
                      @click="predictAmountBet('subtract')"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-down.svg"
                        alt="down"
                      />
                    </button>
                  </div>
                </div>
              </label>
            </div>
            <div class="col-md-6 col-lg-12 col-xl-6">
              <label for="bet-payout">
                <div class="pre-text">
                  <div class="auto-cash">{{ $t('happy.autoCashOut') }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{ disabled: GameStatus !== 'PENDING' || isBet }"
                >
                  <input
                    class="input"
                    id="bet-payout"
                    type="number"
                    v-model="formBet.payout"
                    min="1.01"
                    @focusout="preventOne"
                    onwheel="this.blur()"
                    :disabled="GameStatus !== 'PENDING' || isBet"
                  />
                  <button
                    :disabled="GameStatus !== 'PENDING' || isBet"
                    class="botan cancel"
                    @click="formBet.payout = 100"
                  >
                    <img src="@/assets/images/icons/close-cyan.svg" alt="" />
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false" class="trend actions">
      <div class="interface">
        <div v-if="currentAction === 'manual'" class="manual">
          <div class="row text-left">
            <div class="col-12">
              <label for="bet-amount">
                <div class="pre-text">
                  <div>
                    {{ $t('wallet.amount') }}
                    <img src="@/assets/images/icons/stockUp.svg" alt="" />
                  </div>
                  <div class="min-bet">{{ TypeBalanceSelected }}</div>
                </div>
                <div
                  class="input-box"
                  :class="{
                    disabled:
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon,
                  }"
                >
                  <img
                    class="currency"
                    :src="
                      require(`@/assets/images/currency/${TypeBalanceSelected.toLowerCase()}.png`)
                    "
                    alt=""
                  />
                  <input
                    class="input"
                    type="number"
                    id="bet-amount"
                    v-model="formBetTrend.amountBet"
                    onwheel="this.blur()"
                    :disabled="
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon
                    "
                  />
                  <button
                    :disabled="
                      GameStatus !== 'PENDING' ||
                      isBetRed ||
                      isBetGreen ||
                      isBetMoon
                    "
                    class="botan lesser"
                    @click="
                      formBetTrend.amountBet =
                        BalanceSocket[TypeBalanceSelected]
                    "
                  >
                    ALL IN
                  </button>
                  <button
                    class="botan"
                    @click="predictAmountBet('/2', true)"
                    :disabled="GameStatus !== 'PENDING'"
                  >
                    /2
                  </button>
                  <button
                    class="botan"
                    @click="predictAmountBet('x2', true)"
                    :disabled="GameStatus !== 'PENDING'"
                  >
                    x2
                  </button>
                  <div class="number-roller">
                    <button
                      class="botan"
                      @click="predictAmountBet('add', true)"
                      :disabled="GameStatus !== 'PENDING'"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-up.svg"
                        alt="up"
                      />
                    </button>
                    <button
                      class="botan"
                      @click="predictAmountBet('subtract', true)"
                      :disabled="GameStatus !== 'PENDING'"
                    >
                      <img
                        src="@/assets/images/icons/cyan-arrow-down.svg"
                        alt="up"
                      />
                    </button>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="bet-options">
            <div class="option red">
              {{ $t('dashboard.payout') }} 1,76x
              <button
                class="botan"
                @click="emitBet('red')"
                v-if="!isBetRed"
                :disabled="GameStatus !== 'PENDING' || isBetGreen || isBetMoon"
              >
                <img src="@/assets/images/icons/strawberry.png" />
                WOW Strawberry
              </button>
              <template v-else>
                <!-- <button
                  class="botan red"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('red', true)"
                >
                  Upgrade
                </button> -->
                <button
                  class="botan red"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                >
                  {{ $t('happy.loading') }}
                </button>
                <button v-else class="botan red">Wowing</button>
              </template>
            </div>
            <div class="option green">
              {{ $t('dashboard.payout') }} 2x
              <button
                class="botan"
                @click="emitBet('green')"
                v-if="!isBetGreen"
                :disabled="GameStatus !== 'PENDING' || isBetRed || isBetMoon"
              >
                <img src="@/assets/images/icons/lime.png" />
                WOW Guava
              </button>
              <template v-else>
                <!-- <button
                  class="botan green"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('green', true)"
                >
                  Upgrade
                </button> -->
                <button
                  class="botan green"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                >
                  {{ $t('happy.loading') }}
                </button>
                <button v-else class="botan green">Wowing</button>
              </template>
            </div>
            <div class="option orange">
              {{ $t('dashboard.payout') }} 10x
              <button
                class="botan"
                @click="emitBet('moon')"
                v-if="!isBetMoon"
                :disabled="GameStatus !== 'PENDING' || isBetRed || isBetGreen"
              >
                <img src="@/assets/images/icons/lemon.png" />
                WOW Lemon
              </button>
              <template v-else>
                <!-- <button
                  class="botan orange"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                  @click="emitBet('moon', true)"
                >
                  Upgrade
                </button> -->
                <button
                  class="botan orange"
                  v-if="GameStatus === 'PENDING' || GameStatus === 'END'"
                >
                  {{ $t('happy.loading') }}
                </button>
                <button v-else class="botan orange">Wowing</button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CrashActions',
  props: {
    GameStatus: {
      type: String,
      required: true,
    },
    CounterChart: {
      type: Number,
      required: true,
    },
    StopPoint: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      controlResult: 0,
      controlsValue: 0,
      amountUpgrade: 1,
      formBet: {
        amountBet: 5,
        payout: 100,
        currency: '',
      },
      formBetTrend: {
        amountBet: 5,
        currency: '',
      },
      isBet: false,
      isBetRed: false,
      isBetGreen: false,
      isBetMoon: false,
      currentAction: 'manual',
      currentMode: 'classic',
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'userinfo/UserInfo',
      TypeBalanceSelected: 'socket/TypeBalanceSelected',
      BalanceSocket: 'socket/BalanceSocket',
    }),
  },
  methods: {
    onStop() {
      this.$emit('cashOut');
    },
    async emitBet(betType, upgrade) {
      // if(this.formBet.amountBet < this.UserBalance.BSBalance) {}
      // if(this.formBet.amountBet < this.UserBalance.DBSBalance) {}
      // if(this.formBet.amountBet < this.UserBalance.SBSBalance) {}
      // this.isBet = true;
      if (betType === 'classic') {
        if (
          this.TypeBalanceSelected === 'BS' &&
          +this.formBet.amountBet > +this.BalanceSocket.BS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }
        if (
          this.TypeBalanceSelected === 'SBS' &&
          +this.formBet.amountBet > +this.BalanceSocket.SBS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }
        if (
          this.TypeBalanceSelected === 'DBS' &&
          +this.formBet.amountBet > +this.BalanceSocket.DBS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }

        this.formBet.currency = this.TypeBalanceSelected;
        // if (upgrade) {
        //   this.amountUpgrade += 1;
        //   this.formBet.amountBet =
        //     this.formBet.amountBet / (this.amountUpgrade - 1);
        //   this.formBet.amountBet = this.formBet.amountBet * this.amountUpgrade;
        // }
        this.$emit('userBet', { betType, formBet: this.formBet });
      } else {
        if (
          this.TypeBalanceSelected === 'BS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.BS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }
        if (
          this.TypeBalanceSelected === 'SBS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.SBS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }
        if (
          this.TypeBalanceSelected === 'DBS' &&
          +this.formBetTrend.amountBet > +this.BalanceSocket.DBS
        ) {
          this.$toastr.e(
            this.$t('app.balance_not_enough'),
            this.$t('app.error')
          );
          return;
        }

        this.formBetTrend.currency = this.TypeBalanceSelected;
        // if (upgrade) {
        //   this.amountUpgrade += 1;
        //   this.formBetTrend.amountBet =
        //     this.formBetTrend.amountBet / (this.amountUpgrade - 1);
        //   this.formBetTrend.amountBet =
        //     this.formBetTrend.amountBet * this.amountUpgrade;
        // }
        // if (betType === 'red') {
        //   this.isBetRed = true;
        // }
        // if (betType === 'green') {
        //   this.isBetGreen = true;
        // }
        // if (betType === 'moon') {
        //   this.isBetMoon = true;
        // }
        this.$emit('userBet', { betType, formBet: this.formBetTrend });
      }
    },
    predictAmountBet(type, onTrend) {
      if (onTrend) {
        if (type === '/2') {
          this.formBetTrend.amountBet /= 2;
          if (this.formBetTrend.amountBet < 1) {
            this.formBetTrend.amountBet = 1;
          }
        }
        if (type === 'x2') {
          this.formBetTrend.amountBet *= 2;
          if (this.formBetTrend.amountBet > +this.BalanceSocket) {
            this.formBetTrend.amountBet = +this.BalanceSocket;
          }
        }

        if (type === 'add') {
          this.formBetTrend.amountBet = +this.formBetTrend.amountBet + 1;
        }
        if (type === 'subtract') {
          this.formBetTrend.amountBet = this.formBetTrend.amountBet - 1;
        }
      } else {
        if (type === '/2') {
          this.formBet.amountBet /= 2;
          if (this.formBet.amountBet < 1) {
            this.formBet.amountBet = 1;
          }
        }
        if (type === 'x2') {
          this.formBet.amountBet *= 2;
          if (this.formBet.amountBet > +this.BalanceSocket) {
            this.formBet.amountBet = +this.BalanceSocket;
          }
        }

        if (type === 'add') {
          this.formBet.amountBet = +this.formBet.amountBet + 1;
        }
        if (type === 'subtract') {
          this.formBet.amountBet = this.formBet.amountBet - 1;
        }
      }
    },
    preventOne(event) {
      const target = event.target;
      if (target.value <= 1 || target.value === '1') {
        this.formBet.payout = 1.01;
      }
    },
    setControlValue() {
      this.$emit('adminControls', +this.controlsValue);
    },
    setControlsTrend(input) {
      this.$emit('adminControls', input);
    },
  },
  watch: {
    formBet: {
      handler(newVal) {
        // console.log(newVal);
        if(newVal){
          if(newVal.amountBet) {
            const amount = +newVal.amountBet;
            const amountSplit = amount.toString().split(".");
            // console.log(amountSplit);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-wrapper {
  padding: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: column-reverse;
  .game-mode-tabs {
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    .tabs-wrapper {
      display: flex;
      margin-top: 15px;
      margin-bottom: 0px;
      > div {
        padding: 5px 15px;
        cursor: pointer;
      }
    }
    @media (min-width: 992px) {
      justify-content: flex-end;
    }
  }
  .actions {
    color: white;
    display: grid;
    grid-template-columns: auto;
    .tabs {
      display: flex;
      writing-mode: vertical-rl;
      // text-orientation: sideways-right;
      font-weight: 700;
      font-size: 12px;
      > div {
        padding: 30px 5px;
        transform: rotate(180deg);
        cursor: pointer;
        transition: 0.4s;
        flex: 1;
        text-align: center;
        &.gradient-border {
          &.active {
            --gradient: linear-gradient(#0ec5ff 1.08%, #3964fb 83%);
          }
        }
      }
      @media (min-width: 768px) {
        font-size: 14px;
        > div {
          padding: 30px 10px;
        }
      }
    }
    .interface {
      padding: 15px 0px;
      .manual {
        text-align: center;
        .bet {
          max-width: 316px;
          width: 60%;
          margin-bottom: 30px;
          padding: 10px 15px;
          line-height: 1em;
          font-weight: 700;
          min-height: 55px;
          @media (min-width: 768px) {
            padding: 15px;
            width: 100%;
          }
          transition: all 0.4s, transform 0.1s;
          &:active {
            transform: scale(0.98);
          }
        }
        .login {
          grid-column: 2;
          margin-top: 10px;
        }
        label {
          width: 100%;
          font-size: 14px;
          padding: 10px 0px;
          .pre-text {
            display: flex;
            justify-content: space-between;
            padding: 0px 10px;
            .min-bet {
              color: #d3def4;
            }
            .auto-cash {
              color: rgba(255, 255, 255, 0.4);
            }
            .auto-chance {
              color: rgba(255, 255, 255, 0.4);
              b {
                color: #00c2ff;
              }
            }
          }
          .input-box {
            display: flex;
            padding: 4px;
            transition: 0.4s;
            &.disabled {
              filter: grayscale(0.8);
            }
            .currency {
              width: 19px;
              aspect-ratio: 1;
              object-fit: contain;
            }
            .input {
              font-size: 16px;
              padding: 0px 10px;
              font-weight: 600;
            }
            .botan {
              background: #495a88;
              border-radius: 2px;
              padding: 8px 18px;
              margin-right: 2px;
              transition: 0.2s;
              color: #99a4b0;
              white-space: nowrap;
              &.cancel {
                background: transparent;
              }
              &:hover {
                background: #5b70a9;
              }
              &.lesser {
                padding: 8px;
              }
            }
            .number-roller {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .botan {
                padding: 0px 18px;
                line-height: 0;
                flex-grow: 1;
                &:first-child {
                  border-radius: 2px 2px 0px 0px;
                  img {
                    vertical-align: bottom;
                  }
                }
                &:last-child {
                  border-radius: 0px 0px 2px 2px;
                  img {
                    vertical-align: top;
                  }
                }
              }
            }
          }
        }
      }

      .bet-options {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        gap: 10px;
        padding-top: 10px;
        .option {
          text-align: left;
          .botan {
            text-align: center;
            display: block;
            position: relative;
            background-color: #1b2c57;
            padding: 15px;
            width: 100%;
            border-radius: 4px;
            line-height: 1em;
            font-weight: 700;
            transition: 0.4s, transform 0.1s;
            &.red {
              background-color: red;
            }
            &.green {
              background-color: green;
            }
            &.orange {
              background-color: orange;
            }
            &:hover {
              filter: brightness(1.2);
            }
            &:disabled {
              filter: grayscale(80%);
              cursor: not-allowed;
            }
            &:active {
              transform: scale(0.98);
            }
          }
          img {
            width: 23px;
            aspect-ratio: 1;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
          }
        }
        @media (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      @media (min-width: 992px) {
        padding: 15px 10px;
      }
    }
  }
  @media (min-width: 992px) {
    flex-direction: column;
  }
}
</style>
