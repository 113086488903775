<template>
  <div class="trendBall">
    <div class="trendBall-content">
      <div class="trendBall-vs">
        <img src="@/assets/images/versus.svg" alt="" />
      </div>
      <div class="trendBall-item">
        <div class="trendBall-item-header">
          <img src="https://bc.game/assets/trenball_red.325f3400.png" alt="" />
          <h3 class="redBear">Red Bear</h3>
          <div class="redBear-item-total">
            <p><span>{{ $t('happy.player') }}</span> {{ totalPlayerBetRed }}</p>
            <p class="d-flex align-items-center mb-0">
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="mr-1 mb-0"
                style="width: 17px !important"
              />
              {{ TruncateToDecimals2(totalAmountBetRed, "", 2) }}
            </p>
          </div>
        </div>
        <div class="trendBall-item-content">
          <p>{{ $t('happy.player') }}</p>
          <p>Wow</p>
        </div>
      </div>
      <div class="trendBall-item">
        <div class="trendBall-item-header">
          <img
            src="https://bc.game/assets/trenball_green.86008f7e.png"
            alt=""
          />
          <h3 class="greenBull">Green Bull</h3>
          <div class="greenBull-item-total">
            <p><span>{{ $t('happy.player') }}</span> {{ totalPlayerBetGreen }}</p>
            <p>
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="mr-1 mb-0"
                style="width: 17px !important"
              />
              {{ TruncateToDecimals2(totalAmountBetGreen, "", 2) }}
            </p>
          </div>
        </div>
        <div class="trendBall-item-content">
          <p>{{ $t('happy.player') }}</p>
          <p>Wow</p>
        </div>
      </div>
    </div>
    <template
      v-if="
        (AllBetRed && AllBetRed.length > 0) ||
        (AllBetGreen && AllBetGreen.length > 0)
      "
    >
      <div class="trendBall-bet" id="trendBall-bet">
        <div class="trendBall-bet-list redBear">
          <div
            class="trendBall-bet-item"
            v-for="(bet, idx) in AllBetRed"
            :key="'red' + idx"
          >
            <p>
              <img src="~@/assets/images/mockup/bet-list.png" alt="" />
              {{ bet.username | overflowed }}
            </p>
            <p
              :class="
                StatusBetGreen === 'PENDING'
                  ? 'text-white'
                  : !StatusBetGreen
                  ? 'text-win'
                  : 'text-lose'
              "
            >
              {{ TruncateToDecimals2(+bet.amountBet, "", 2) }}
              <img
                :src="
                  require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`)
                "
                alt=""
              />
            </p>
          </div>
        </div>
        <div class="trendBall-bet-list greenBull">
          <div
            class="trendBall-bet-item"
            v-for="(bet, idx) in AllBetGreen"
            :key="'green' + idx"
          >
            <p>
              <img src="~@/assets/images/mockup/bet-list.png" alt="" />
              {{ bet.username | overflowed }}
            </p>
            <p
              :class="
                StatusBetGreen === 'PENDING'
                  ? 'text-white'
                  : StatusBetGreen
                  ? 'text-win'
                  : 'text-lose'
              "
            >
              {{ TruncateToDecimals2(+bet.amountBet, "", 2) }}
              <img
                :src="
                  require(`@/assets/images/currency/${bet.currency.toLowerCase()}.png`)
                "
                alt=""
              />
            </p>
          </div>
        </div>
      </div>
      <div class="trendBall-button" v-if="false">
        <b-button class="btnNoStyle" @click="changeStatusShowMore">
          Show {{ !showMore ? "More" : "Less" }}
          <img
            src="~@/assets/images/icons/icon-down.png"
            alt=""
            v-if="!showMore"
          />
          <img src="~@/assets/images/icons/icon-up.png" alt="" v-else />
        </b-button>
      </div>
    </template>
    <p v-else class="text-center mt-5 text-white font-weight-bold">
      Nothing here
    </p>
  </div>
</template>

<script>
export default {
  name: "BetListedTrendBallPageView",
  props: {
    AllUserBet: {
      type: Object,
      required: true,
    },
    MessageSocket: {
      type: Object,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    StatusBetGreen() {
      let status = "PENDING";
      const arrBetGreenFull = this.AllUserBet.bet_green;
      const arrBetGreenWithStatus = arrBetGreenFull.filter(
        (bet) => !bet.amountBet
      );
      if (arrBetGreenWithStatus.length > 0) {
        status = arrBetGreenWithStatus[0].status;
      } else status = "PENDING";
      return status;
    },
    AllBetRed() {
      let arr = [];
      // const arrBetRedFull = this.AllUserBet.bet_red;
      // arr = arrBetRedFull.filter((bet) => bet.amountBet > 0);
      return arr;
    },
    AllBetGreen() {
      let arr = [];
      // const arrBetGreenFull = this.AllUserBet.bet_green;
      // arr = arrBetGreenFull.filter((bet) => bet.amountBet > 0);
      return arr;
    },
    totalPlayerBetRed() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetRed.length;
      return totalPlayer;
    },
    totalAmountBetRed() {
      let totalBet = 0;
      totalBet = this.AllBetRed.reduce(
        (total, item) => total + item.amountBet,
        0
      );
      return totalBet;
    },
    totalPlayerBetGreen() {
      let totalPlayer = 0;
      totalPlayer = this.AllBetGreen.length;
      return totalPlayer;
    },
    totalAmountBetGreen() {
      let totalBet = 0;
      totalBet = this.AllBetGreen.reduce(
        (total, item) => total + item.amountBet,
        0
      );
      return totalBet;
    },
  },
  methods: {
    changeStatusShowMore() {
      const myDiv = document.getElementById("trendBall-bet");
      if (this.showMore) {
        myDiv.style.flexBasis = "auto";
        this.showMore = false;
      } else {
        myDiv.style.flexBasis = myDiv.offsetHeight + "px";
        this.showMore = true;
      }
      myDiv.scrollTop = 0;
    },
  },
  filters: {
    overflowed: (value) => {
      if (!value) return "";
      const preferLen = 8;
      if (typeof value === "string" && value.length >= preferLen) {
        return value.slice(0, preferLen) + "...";
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trendBall {
  height: 100%;
  background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .trendBall-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    .trendBall-vs {
      position: absolute;
      top: 10%;
      left: 44%;
      img {
        width: 40px;
      }
    }
    .trendBall-item {
      width: 49%;
      .trendBall-item-header {
        text-align: center;
        img {
          width: 100px;
          margin-bottom: 10px;
        }
        h3 {
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 10px;
          &.redBear {
            color: #be1f2d;
          }
          &.greenBull {
            color: #24ff00;
          }
        }
        .greenBull-item-total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid #24ff00;
          background-image: linear-gradient(
            to bottom,
            rgba(93, 160, 0, 0.2),
            rgba(0, 0, 0, 0)
          );
          padding: 10px;
          p {
            font-size: 14px;
            color: #24ff00;
            margin-bottom: 0px;
            span {
              color: #d4dff4;
            }
          }
        }
        .redBear-item-total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 3px solid #be1f2d;
          background-image: linear-gradient(
            to bottom,
            rgba(190, 31, 44, 0.2),
            rgba(0, 0, 0, 0)
          );
          padding: 10px;
          p {
            font-size: 14px;
            color: #24ff00;
            margin-bottom: 0px;
            span {
              color: #d4dff4;
            }
          }
        }
      }
      .trendBall-item-content {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #d4dff4;
        p {
          margin-bottom: 0px;
          color: #d4dff4;
        }
      }
    }
  }
  .trendBall-bet {
    flex: 1;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    flex-basis: 580px;
    overflow: auto;
    .trendBall-bet-list {
      width: 48%;
      .trendBall-bet-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        p {
          width: 49%;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: white;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap &:nth-child(1) {
            overflow: hidden;
            justify-content: flex-start;
            img {
              width: 25px;
              margin-right: 3px;
            }
          }
          &:nth-child(2) {
            overflow: hidden;
            justify-content: flex-end;
            img {
              width: 15px;
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
  .trendBall-button {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    button {
      background: #1a1a1a;
      color: #1b2c57;
      img {
        margin-left: 5px;
      }
    }
  }
}
</style>
