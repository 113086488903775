<template>
  <div class="chartCrash">
    <div class="chart-header" :class="{ admin: UserInfo.level }">
      <div class="balance user">
        <div class="text-center">
          <span>{{ $t("wallet.amount") }}</span> {{ TypeBalanceSelected }}
        </div>
        <div class="amount">
          {{ TruncateToDecimals2(+BalanceSocket[TypeBalanceSelected], "", 2) }}
          <img
            v-if="TypeBalanceSelected === 'BS'"
            src="@/assets/images/currency/bs.png"
            alt=""
            width="20px"
          />
          <img
            v-if="TypeBalanceSelected === 'SBS'"
            src="@/assets/images/currency/sbs.png"
            alt=""
            width="20px"
          />
        </div>
      </div>
      <!-- UserInfo.level -->
      <div class="balance" v-if="false">
        <div class="text-center">
          <span>{{ $t("wallet.amount") }}</span>
        </div>
        <div class="amount">
          {{ TruncateToDecimals2(PoolSize, "", 2) }}
          <!-- <img src="@/assets/images/currency/sbs.png" alt="" width="20px"/> -->
        </div>
      </div>
      <div class="game-history">
        <!-- Bank Rolll -->
        <div class="history">
          <div class="row">
            <div
              v-for="item in BankRoll"
              :key="item.hash"
              class="col-auto flex-grow-1"
            >
              <div class="game">
                {{
                  `...${item.hash.slice(
                    item.hash.length - 6,
                    item.hash.length
                  )}`
                }}
              </div>
              <div
                class="result"
                :class="{
                  green: item.payout >= 2,
                  orange: item.payout >= 10,
                }"
              >
                {{ TruncateToDecimals2(item.payout, "", 2)
                }}{{ item.payout % 1 === 0 ? ".00" : "" }}x
              </div>
            </div>
          </div>
        </div>
        <!-- Trend -->
        <div class="trend">
          <button
            @click="showTrend = !showTrend"
            class="botan toggle"
            :class="{ active: showTrend }"
          >
            <img src="@/assets/images/icons/trend.svg" alt="" />
            {{ $t("happy.trend") }}
          </button>
          <transition name="fade">
            <div v-if="showTrend" class="trend-table-wrapper">
              <div class="trend-head">
                {{ $t("happy.trend") }}
                <button @click="showTrend = false" class="close botan">
                  <img src="@/assets/images/icons/close-white.svg" alt="" />
                </button>
              </div>
              <div
                class="trend-table"
                v-if="HistorySystem && HistorySystem.length > 0"
              >
                <div
                  v-for="(item, index) in ForTrendTable"
                  :key="index"
                  :style="{ color: item.color }"
                  class="trend-item"
                >
                  <img
                    v-if="item.color"
                    :src="
                      require(`@/assets/images/icons/${
                        item.color === 'red'
                          ? 'strawberry'
                          : item.color === 'green'
                          ? 'lime'
                          : 'lemon'
                      }.png`)
                    "
                  />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- Chart -->
    <div class="myChart-wrapper">
      <v-chart
        ref="myChart"
        :option="chartOptions"
        :update-options="{ notMerge: false }"
      ></v-chart>
      <div class="countDown-time">
        <p v-if="GameStatus === 'PENDING'" class="gradient-text">
          {{ $t("happy.startIn") }} {{ Countdown }}s
        </p>
        <p v-else-if="GameStatus === 'END'" class="bang">
          {{ $t("happy.bang") }} @{{
            TruncateToDecimals2(CounterChart ? CounterChart : 0, "", 2)
          }}x
        </p>
        <p v-else class="payout gradient-text">
          {{ TruncateToDecimals2(CounterChart ? CounterChart : 0, "", 2) }}x
        </p>
        <div class="ripple">
          <img
            src="@/assets/images/rippleSlice.svg"
            style="animation-delay: 600ms"
          />
          <img
            src="@/assets/images/rippleSlice.svg"
            style="animation-delay: 12000ms"
          />
          <img
            src="@/assets/images/rippleSlice.svg"
            style="animation-delay: 1800ms"
          />
          <img
            src="@/assets/images/rippleSlice.svg"
            style="animation-delay: 2400ms"
          />
          <img
            src="@/assets/images/rippleSlice.svg"
            style="animation-delay: 3000ms"
          />
          <img src="@/assets/images/rippleSlice.svg" style="animation: none" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChartCrashPageView",
  props: {
    PoolSize: {
      type: Number,
      default: 0,
    },
    HistorySystem: {
      type: Array,
    },
    MessageSocket: {
      type: Object,
    },
    GameStatus: {
      type: String,
    },
    Countdown: {
      type: Number,
      default: 0,
    },
    CounterChart: {
      type: Number,
      default: 0,
    },
    ChartData: {
      type: Object,
    },
    UserStop: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fakeData: {
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [
          [0, 10.1],
          [8, 20],
        ],
      },
      data: {
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [],
      },
      chartConfig: {
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: (value) => {
              return `${value}`;
            },
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          min: 10.1,
          scale: true,
          axisLabel: {
            formatter: (value) => {
              return `${value / 10}x`;
            },
          },
          axisLine: {
            show: true,
          },
          splitNumber: 5,
        },
        grid: {
          show: false,
          id: "xGrid",
          left: 40,
          bottom: 30,
          top: 20,
        },
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "white", // color at 0%
              },
              {
                offset: 1,
                color: "#00A3FF", // color at 100%
              },
            ],
            global: true, // default is false
          },
          "transparent",
        ],
        animationDurationUpdate: 100,
      },
      graphic: [],
      chartWidth: 0,
      showTrend: false,
      firstTime: true,
    };
  },
  computed: {
    ...mapGetters({
      BalanceSocket: "socket/BalanceSocket",
      User: "userinfo/User",
      UserInfo: "userinfo/UserInfo",
      TypeBalanceSelected: "socket/TypeBalanceSelected",
    }),
    chartOptions() {
      const newOptions = { ...this.chartConfig, graphic: this.graphic };
      newOptions.series = [this.data, this.fakeData];
      return newOptions;
    },
    BankRoll() {
      let arr = [];
      arr = this.HistorySystem.slice(0, 8);
      return arr;
    },
    ForTrendTable() {
      const col = 16;
      let pointer = 0;
      let buckets = [[]];
      const reversedHistory = this.HistorySystem.toReversed();
      let streak = this.getPayoutClass(reversedHistory[0].payout);

      for (let i = 0; i < reversedHistory.length; i += 1) {
        const payoutClass = this.getPayoutClass(reversedHistory[i].payout);
        const color = this.getPayoutColor(reversedHistory[i].payout);
        if (payoutClass === streak) {
          if (buckets[pointer].length >= 6) {
            pointer += 1;
            buckets[pointer] = [];
          }
          buckets[pointer].push({ color });
        } else {
          pointer += 1;
          streak = payoutClass;
          buckets[pointer] = [];
          buckets[pointer].push({ color });
        }
      }

      buckets.reverse();
      buckets = buckets.slice(0, col);
      buckets.reverse();

      const final = [];
      for (let i = 0; i < buckets.length; i += 1) {
        for (let u = 0; u < 6; u += 1) {
          if (buckets[i][u] === undefined) {
            final.push({ color: undefined });
          } else {
            final.push(buckets[i][u]);
          }
        }
      }
      return final;
    },
  },
  watch: {
    MessageSocket: {
      handler(newVal) {
        if (newVal.action === "BET") {
          if (newVal.status === true) {
            this.isBet = true;
          } else {
            this.isBet = false;
          }
        }
        if (newVal.action === "BET_RED" && newVal.status === true) {
          this.isBetRed = true;
        }
        if (newVal.action === "BET_GREEN" && newVal.status === true) {
          this.isBetGreen = true;
        }
        if (newVal.action === "BET_MOON" && newVal.status === true) {
          this.isBetMoon = true;
        }
      },
      deep: true,
    },
    ChartData: {
      handler(newVal) {
        if (newVal) {
          this.updateChart();
        }
      },
      deep: true,
    },
    UserStop: {
      handler(newVal) {
        if (newVal && newVal.username) {
          this.spawnPoints(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    spawnPoints(user) {
      const { length } = this.data.data;
      const dataPoints = this.data.data[length - 1];
      if (dataPoints) {
        const pos = this.$refs.myChart.convertToPixel(
          { gridId: "xGrid" },
          dataPoints
        );
        const axisPos = this.$refs.myChart.convertToPixel(
          { gridId: "xGrid" },
          [0, 0]
        );
        this.graphic.push({
          id: `grap${this.graphic.length}`,
          type: "group",
          x: pos[0],
          y: axisPos[1],
          children: [
            {
              type: "circle",
              shape: {
                r: 3,
              },
              style: {
                fill: "white",
                opacity: 0,
              },
              dataPos: dataPoints,
              enterFrom: {
                style: {
                  opacity: 0.5,
                },
              },
              enterAnimation: {
                duration: 6000,
                easing: "linear",
              },
            },
            {
              type: "text",
              y: 0,
              style: {
                text: user.username,
                fill: "white",
                opacity: 0,
                textAlign: "center",
                font: "14px sans serif",
              },
              enterFrom: {
                style: {
                  opacity: 0.5,
                },
              },
              enterAnimation: {
                duration: 6000,
                easing: "linear",
              },
            },
          ],
          enterFrom: {
            y: pos[1],
          },
          enterAnimation: {
            duration: 6000,
            easing: "linear",
          },
          dataPos: dataPoints,
        });
      }
    },

    handleResize() {
      this.$refs.myChart.resize();
      this.chartWidth = this.$refs.myChart.getWidth();
      this.chartConfig.color[0].x2 = this.chartWidth;
    },

    scaleFunction(x) {
      return 10.1 * 1.01 ** x;
    },

    generateData() {
      const data = [];
      const time = Math.round(
        Math.log(this.ChartData.respond) / Math.log(1.01)
      );
      for (let i = 0; i <= time / 10; i += 0.1) {
        const value = this.scaleFunction(i * 10);
        data.push([i, value]);
      }
      return data;
    },

    startChart() {
      this.data.data = this.generateData();
    },

    stopChart() {
      this.data.data = [];
      this.graphic = [];
      this.$refs.myChart.clear();
    },

    updateChart() {
      this.data.data = this.generateData();
      for (let i = 0; i < this.graphic.length; i += 1) {
        const pos = this.$refs.myChart.convertToPixel(
          { gridId: "xGrid" },
          this.graphic[i].dataPos
        );
        // eslint-disable-next-line prefer-destructuring
        this.graphic[i].x = pos[0];
      }
    },

    getPayoutClass(payout) {
      if (payout >= 2) {
        return "big";
      }
      return "small";
    },

    getPayoutColor(payout) {
      if (payout >= 10) {
        return "orange";
      }
      if (payout >= 2) {
        return "green";
      }
      return "red";
    },
    addHistory(payout) {
      this.history.unshift({
        payout,
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.chartCrash {
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
  display: grid;
  grid-template-rows: auto;
  .chart-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    color: white;
    font-weight: 700;
    &.admin {
      .balance {
        &.user {
          grid-column: unset;
        }
      }
    }
    .balance {
      background-color: #1b2c57;
      color: #969696;
      border-radius: 2px;
      padding: 3px 10px;
      font-size: 14px;
      line-height: 1.1;
      &.user {
        grid-column: 1/-1;
      }
      span {
        color: #3bc117;
      }
      .amount {
        color: white;
        text-align: center;
      }
    }
    .history {
      background-color: #1b2c57;
      border-radius: 2px;
      padding: 0px 5px;

      flex-grow: 1;
      overflow: hidden;

      font-size: 1rem;
      max-height: 41px;
      .row {
        margin: 0px -5px;
        // flex-wrap: nowrap;

        justify-content: flex-end;
        flex-direction: row-reverse;
        > div {
          padding: 5px;
          flex-basis: 90px;
        }
        .game {
          font-weight: 500;
          font-size: 10px;
          color: #969696;
        }
        .result {
          color: #ff0000;
          line-height: 1;
          &.green {
            color: #24ff00;
          }
          &.orange {
            color: #ff7f09;
          }
        }
      }
    }
    .trend {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: rgba(255, 255, 255, 1);
      position: relative;
      z-index: 2;
      padding-left: 10px;
      .botan.toggle {
        transition: opacity 0.4s;
        opacity: 0.3;
        &:hover,
        &.active {
          opacity: 0.6;
        }
      }
      img {
        margin-right: 5px;
      }
      .trend-table-wrapper {
        background: #0e0e0e;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: -10px;
        padding: 15px;
        max-width: calc(100vw - 20px);
        @media (max-width: 576px) {
          padding: 10px;
        }
        .trend-head {
          position: relative;
          text-align: center;
          .close {
            position: absolute;
            top: 0;
            right: 0;
            line-height: 0;
            img {
              width: 15px;
              object-fit: contain;
            }
          }
        }
        .trend-table {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: repeat(16, 25px);
          grid-template-rows: repeat(6, 25px);
          gap: 2px;
          padding-top: 15px;
          overflow: hidden;
          max-width: 100%;
          justify-content: end;
          .trend-item {
            background-color: #2c2f36;
            position: relative;
            color: transparent;
            img {
              position: absolute;
              object-fit: contain;
              width: 100%;
              aspect-ratio: 1;
            }
          }
        }
        .trend-guide {
          padding-top: 15px;
        }
      }
    }
    .game-history {
      display: flex;
      grid-column: 1/ -1;
      grid-row: 2;
    }
    @media (min-width: 768px) {
      grid-template-columns: auto 1fr;
      .game-history {
        grid-column: unset;
        grid-row: unset;
      }
      .balance {
        &.user {
          grid-column: unset;
        }
      }
      &.admin {
        grid-template-columns: auto 1fr;
      }
    }
    @media (min-width: 1200px) {
      .trend {
        .trend-table-wrapper {
          left: calc(100% + 15px);
          right: auto;
        }
      }
    }
  }
  .myChart-wrapper {
    position: relative;
    perspective: 1000px;
    width: 100%;
    aspect-ratio: 3.9/2;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      top: 50%;
      background: linear-gradient(
          180deg,
          rgba(255, 245, 245, 0) 0%,
          rgba(255, 255, 255, 0.16) 100%
        )
        no-repeat 100%;
      transform-origin: bottom;
      perspective: 300;
      transform: rotateX(85deg);
      transform-style: preserve-3d;
    }
    @media (min-width: 768px) {
      padding-bottom: 20px;
      aspect-ratio: 5/2;
    }
    .countDown-time {
      position: absolute;
      width: 120px;
      height: 40px;
      top: 40px;
      left: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      transform: translate(-50%, -50%);
      p {
        white-space: nowrap;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 1px;
        position: relative;
        z-index: 2;
        margin: 0;
        &.payout {
          font-size: 20px;
        }
        &.bang {
          color: #ff0000;
        }
      }
      .ripple {
        z-index: 1;
        position: absolute;
        inset: 0;
        img {
          position: absolute;
          width: 100%;
          object-fit: contain;
          animation: rippling 3s infinite linear;
        }
        @keyframes rippling {
          to {
            opacity: 0;
            transform: scale(1.2);
          }
        }
      }
      @media (min-width: 576px) {
        width: 180px;
        height: 50px;
        top: 25%;
        left: 30%;
        p {
          padding-top: 12px;
          font-size: 24px;
          &.payout {
            font-size: 40px;
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-leave-to,
.fade-enter-from {
  opacity: 0;
}
</style>
