<template>
  <div class="crash-game-page">
    <div class="crash-game-wrapper">
      <div class="crash-game" :class="{ go: gameStatus === 'START' }">
        <CrashGame
          ref="gameChart"
          :PoolSize="gamePoolSize"
          :HistorySystem="gameHistoryResult"
          :MessageSocket="gameMessageSocket"
          :GameStatus="gameStatus"
          :Countdown="gameCountDown"
          :CounterChart="gameTiming"
          :ChartData="gameChartData"
          :UserStop="cashOutUser"
        ></CrashGame>
        <CrashActions
          v-if="IsNetWork === 'MAIN'"
          ref="gameAction"
          :GameStatus="gameStatus"
          :CounterChart="gameTiming"
          :StopPoint="gameStopPoint"
          @userBet="handleBet"
          @cashOut="onStop"
          @adminControls="adminControls"
        ></CrashActions>
        <CrashActionsBeta
          v-else
          ref="gameAction"
          :GameStatus="gameStatus"
          :CounterChart="gameTiming"
          :StopPoint="gameStopPoint"
          :MessageUser="messageUser"
          @userBet="handleBet"
          @cashOut="onStop"
          @adminControls="adminControls"
          @userSetStopPoint="onUserSetStopPoint"
        ></CrashActionsBeta>
      </div>
      <div class="table-header gradient-text">
        <div>{{ $t("happy.all") }} Wow</div>
        <div v-if="false">
          <button
            class="botan"
            v-if="currentTable === 'classic'"
            @click="currentTable = 'trend'"
          >
            <img src="@/assets/images/icons/dices.svg" />
            Trendball
          </button>
          <button
            class="botan"
            v-if="currentTable === 'trend'"
            @click="currentTable = 'classic'"
          >
            <img src="@/assets/images/icons/dices.svg" />
            Classic
          </button>
        </div>
        <div>
          Trendball
          <div class="privacy-switch">
            <input type="checkbox" id="gaming" v-model="currentTable" />
            <label for="gaming">Toggle</label>
          </div>
        </div>
      </div>
      <div class="crash-table">
        <BetListedTrendBall
          v-if="currentTable"
          :AllUserBet="gameAnyBetting"
        ></BetListedTrendBall>
        <BetListedClassic
          v-else
          :AllUserBet="gameAnyBetting"
        ></BetListedClassic>
      </div>
    </div>
    <div class="sub-content">
      <div class="text-center text-lg-left">
        <div class="tabs-wrapper">
          <button
            class="botan gradient-border withBG withBlur"
            :class="{ active: currentSub === 'crash' }"
            @click="currentSub = 'crash'"
          >
            Happy
          </button>
          <button
            class="botan gradient-border withBG withBlur"
            :class="{ active: currentSub === 'desc' }"
            @click="currentSub = 'desc'"
          >
            {{ $t("stake.description") }}
          </button>
        </div>
      </div>
      <HistoryCrash
        v-if="currentSub === 'crash'"
        :HistoryBetUser="UserBetHistory"
        :HistorySystem="gameHistoryResult"
      ></HistoryCrash>
      <Description v-if="currentSub === 'desc'"></Description>
    </div>
    <div class="provider">
      {{ $t("happy.aboutTheProvider") }}
      <div class="image-wrapper">
        <img src="@/assets/images/logo-full.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import CrashGame from "../components/crashgame/CrashGame.vue";
import HistoryCrash from "../components/crashgame/HistoryCrash.vue";
import Description from "../components/crashgame/Description.vue";
import BetListedClassic from "../components/crashgame/BetListedClassic.vue";
import BetListedTrendBall from "../components/crashgame/BetListedTrendBall.vue";
import CrashActions from "../components/crashgame/CrashActions.vue";
import CrashActionsBeta from "../components/crashgame/CrashActionsBeta.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CrashPage",
  components: {
    CrashGame,
    HistoryCrash,
    Description,
    BetListedClassic,
    BetListedTrendBall,
    CrashActions,
    CrashActionsBeta,
  },
  data() {
    return {
      currentSub: "crash",
      currentTable: false,

      // define socket data
      socket: null,
      socketStatus: null,

      // game information for current user
      gameStatus: "CONNECTING",
      userCurrentBet: null,
      tempBalance: 0,
      gameChartData: {},
      gameHistoryResult: [],
      gameMessageSocket: {},
      gameAnyBetting: {
        bet_classic: [],
        bet_red: [],
        bet_green: [],
      },
      gameTiming: 0,
      gameCountDown: 0,
      gamePoolSize: 0,
      gameStopPoint: 0,
      userBalanceSelected: "BS",
      cashOutUser: {},
      // User bet history divided into 3 arrays
      userBetHistory: undefined,
      messageUser: null,
      myTimeout: undefined,
    };
  },
  computed: {
    ...mapGetters({
      TypeBalanceSelected: "socket/TypeBalanceSelected",
      CurrentTab: "socket/CurrentTab",
      IsRefreshDemo: "socket/IsRefreshDemo",
      IsNetWork: "core/IsNetWork",
    }),
    UserBetHistory() {
      if (this.userBetHistory) {
        switch (this.CurrentTab) {
          case "userBet":
            return this.userBetHistory.user_history;
          case "userBetMain":
            return this.userBetHistory.user_history_main;
          case "userBetStaking":
            return this.userBetHistory.user_history_staking;
        }
      }
      return {};
    },
    CashedOut() {
      const len = this.gameAnyBetting.bet_classic.lenght;
      if (len > 0) {
        return this.gameAnyBetting.bet_classic[len - 1];
      }
      return {};
    },
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    async connectWSSToken(tokens) {
      let self = this;
      // VUE_APP_SOCKET_BETA
      // VUE_APP_SOCKET_MAIN
      // eslint-disable-next-line no-undef
      this.socket = await io(process.env.VUE_APP_SOCKET_BETA, {
        reconnectionDelayMax: 10000,
        reconnection: false,
        query: {
          token: tokens,
          lang: this.$i18n.locale,
          chooseGame: 1,
        },
      });

      this.socket.on("COUNT_DOWN", (data) => {
        self.gameCountDown = data;
      });

      this.socket.on("CHANGE_STATUS", (data) => {
        this.gameStatus = data;
        if (data === "END") {
          self.userCurrentBet = null;
          const gameChart = self.$refs.gameChart;
          if (gameChart) {
            gameChart.stopChart();
          }
          this.resetGame();
        }
      });

      this.socket.on("CHART_DATA", (data) => {
        // Socket returns an array, get last item in array
        this.gameChartData = data[data.length - 1];
      });

      this.socket.on("GET_BALANCE", (data) => {
        this.tempBalance = data;
        this.$store.commit("socket/SET_BALANCE", data);
      });

      this.socket.on("STOP_POINT", (data) => {
        this.gameStopPoint = +data.stop_point;
      });

      this.socket.on("HISTORY", (data) => {
        this.gameHistoryResult = data.data;
      });

      this.socket.on("GET_BET_RECONNECT", (data) => {
        this.userCurrentBet = data;
      });

      this.socket.on("GET_ALL_USER_BET", (data) => {
        this.gameAnyBetting = data;
      });

      this.socket.on("private-message", (data) => {
        if (data.action === "SUCCESS") {
          this.$vToastify.success(data.message, "Congratulations !");
        } else {
          this.$vToastify.error("", data.message);
        }
      });

      this.socket.on("HISTORY_USER", (data) => {
        this.userBetHistory = data;
      });

      this.socket.on("COUNTER", (data) => {
        this.gameTiming = +data;
      });
      this.socket.on("GET_ALL_USER_STOP", (data) => {
        const myArray = data;
        this.cashOutUser = myArray[myArray.length - 1];
      });

      this.socket.on("POOL_SIZE", (data) => {
        this.gamePoolSize = data;
      });

      this.socket.on("MESSAGE", (data) => {
        this.messageUser = data;
        if (data.status) {
          this.$vToastify.success(data.message, "Success!");
        } else {
          this.$vToastify.error("", data.message);
        }

        // Handle admin control
        if (
          data.status &&
          data.action === "set stop point" &&
          this.$refs.gameAction
        ) {
          this.$refs.gameAction.controlResult = data.result;
        }

        // Handle failed bet
        if (!data.status && this.$refs.gameAction) {
          switch (data.action) {
            case "BET":
              this.$refs.gameAction.isBet = false;
              break;
            case "BET_RED":
              this.$refs.gameAction.isBetRed = false;
              break;
            case "BET_GREEN":
              this.$refs.gameAction.isBetGreen = false;
              break;
            case "BET_MOON":
              this.$refs.gameAction.isBetMoon = false;
          }
        }

        // Handle success cash out
        if (
          data.status &&
          (data.action === "STOP_BET" || data.action === "Payout Win")
        ) {
          const myMessage =
            this.TruncateToDecimals2(data.profit, "", 2) + " " + data.currency;

          this.$store.commit("socket/SET_POP_TOAST", {
            message: myMessage,
            chara: "sonic",
            currency: data.currency.toLowerCase(),
          });
          this.$bvModal.show("notiPop");
          clearTimeout(this.myTimeout);

          this.myTimeout = setTimeout(() => {
            this.$bvModal.hide("notiPop");
          }, 3000);
        }
      });

      this.socket.on("GET_BET_RECONNECT", (data) => {
        if (data && this.$refs.gameAction) {
          if (data.betClassic) {
            this.$refs.gameAction.isBet = true;
          } else {
            this.$refs.gameAction.isBet = false;
          }
          if (data.resultBetGreen) {
            this.$refs.gameAction.isBetGreen = true;
          } else {
            this.$refs.gameAction.isBetGreen = false;
          }
          if (data.resultBetMoon) {
            this.$refs.gameAction.isBetMoon = true;
          } else {
            this.$refs.gameAction.isBetMoon = false;
          }
          if (data.resultBetRed) {
            this.$refs.gameAction.isBetRed = true;
          } else {
            this.$refs.gameAction.isBetRed = false;
          }
        }
      });
    },
    onBet(formBet) {
      if (formBet.amountBet < 1) {
        this.$refs.gameAction.formBet.amountBet = 1;
        this.$vToastify.error(
          "Wow amount must be greater than or equal to 1",
          "Oops!"
        );
        return;
      }
      this.$refs.gameAction.isBet = true;
      this.socket.emit("BET", formBet);
    },
    onStop() {
      this.socket.emit("STOP");
      this.resetGame();
    },
    onUserSetStopPoint(number) {
      this.socket.emit("ADMIN_SET_POINT", { type: "CLASSIC", value: number });
    },
    onBetRed(formBet) {
      this.socket.emit("BET_RED", formBet);
    },
    onBetGreen(formBet) {
      this.socket.emit("BET_GREEN", formBet);
    },
    onBetYellow(formBet) {
      this.socket.emit("BET_MOON", formBet);
    },
    handleBet({ betType, formBet }) {
      switch (betType) {
        case "classic":
          this.onBet(formBet);
          break;
        case "red":
          this.onBetRed(formBet);
          break;
        case "green":
          this.onBetGreen(formBet);
          break;
        case "moon":
          this.onBetYellow(formBet);
      }
    },
    async resetGame() {
      const gameAction = await this.$refs.gameAction;
      if (!gameAction) {
        this.resetGame();
      }
      this.$refs.gameAction.isBet = false;
      this.$refs.gameAction.isBetRed = false;
      this.$refs.gameAction.isBetGreen = false;
      this.$refs.gameAction.isBetMoon = false;
      this.$refs.gameAction.controlResult = 0;
      this.gameStopPoint = 0;
    },

    adminControls(input) {
      if (typeof input === "number") {
        this.socket.emit("ADMIN_SET_POINT", { value: input, type: "CLASSIC" });
      } else {
        this.socket.emit("ADMIN_SET_POINT", { type: input });
      }
    },
  },
  watch: {
    IsRefreshDemo: {
      handler(newVal) {
        let self = this;
        if (newVal === "REFRESH") {
          setTimeout(() => {
            self.socket.emit("SET_BALANCE");
          }, 1000);
        }
      },
    },
  },
  async mounted() {
    const self = this;
    const check = setInterval(async () => {
      const gameChart = await self.$refs.gameChart;
      if (gameChart) {
        clearInterval(check);
        return;
      }
    });

    await new Promise((resolve) => setTimeout(resolve, 500));
    this.outload();
    const tokenSocket = window.$cookies.get("socket_session");
    this.connectWSSToken(tokenSocket.key);
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "wallet/DEPOSIT_FROM_BALANCE_MAIN_TO_GAME_SUCCESS":
        case "wallet/DEPOSIT_FROM_BALANCE_STAKE_TO_GAME_SUCCESS":
        case "wallet/DEPOSIT_FROM_BALANCE_PROFIT_STAKE_TO_GAME_SUCCESS":
          this.socket.emit("SET_BALANCE");
          break;
      }
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
    this.unsubscribe();
    this.$store.commit("socket/SET_BALANCE", {});
  },
};
</script>

<style lang="scss" scoped>
.crash-game-page {
  .crash-game-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    .crash-game {
      background-image: url("~@/assets/images/starry.svg"),
        linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);

      // Animation
      background-size: 200% 200%, 100%;
      animation: bgPan 10s linear infinite;
      animation-play-state: paused;
      &.go {
        animation-play-state: running;
      }
      @keyframes bgPan {
        from {
          background-position: 0 0;
        }
        to {
          background-position: 200% -200%;
        }
      }
      ///////////////////////////
    }
    .table-header {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      .privacy-switch {
        height: 25px;
        display: inline-block;
        input[type="checkbox"] {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 50px;
          height: 25px;
          background: #1165948a;
          display: block;
          border-radius: 100px;
          position: relative;
          margin-top: -25px;
        }

        label:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          background: #656b7d;
          border-radius: 90px;
          transition: 0.3s;
        }

        input:checked + label {
          background: rgba(255, 203, 18, 0.1);
        }

        input:checked + label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
          background: #ffcb12;
        }

        label:active:after {
          width: 50px;
        }
      }
    }
    .crash-table {
      padding: 10px;
    }
    @media (min-width: 768px) {
      .crash-table {
        padding: 0;
      }
      .table-header {
        padding: 5px 0px;
      }
    }
    @media (min-width: 992px) {
      grid-template-columns: 7fr 5fr;
      .crash-game {
        grid-row: 2;
      }
      .crash-table {
        grid-row: 2;
      }
      .table-header {
        grid-row: 1;
        grid-column: 2;
        padding: 5px 0px;
      }
    }
  }
  .tabs-wrapper {
    display: inline-block;
    margin: 30px 10px;
    @media (min-width: 768px) {
      margin: 30px 0px;
    }
    .botan {
      padding: 10px 30px;
    }
  }
  .provider {
    padding: 10px;
    padding-top: 30px !important;
    font-weight: 600;
    .image-wrapper {
      margin-top: 30px;
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      padding: 20px;
      img {
        max-width: 100%;
      }
    }
    @media (min-width: 576px) {
      .image-wrapper {
        padding: 40px;
      }
    }
    @media (min-width: 768px) {
      padding: 0px;
    }
  }
  .rewardsPop {
    position: absolute;
    inset: 0;
    z-index: 100;
    background-color: #081e4334;
  }
  @media (min-width: 768px) {
    padding: 1rem;
  }
  @media (min-width: 1400px) {
    padding: 1rem 2rem;
  }
}
</style>
