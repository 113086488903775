<script>
import { mapGetters } from "vuex";

export default {
  name: "GameBoard",
  props: {
    Countdown: {
      type: Number,
      default: 0,
    },
    GameStatus: {
      type: String,
      required: true,
    },
    GameResult: {
      type: Number,
      required: true,
    },
    GamePool: {
      type: Number,
      required: true,
    },
    GameHistory: {
      type: Array,
      default: () => [],
    },
    TotalCountdown: {
      type: Number,
      default: 0,
    },
    UserBet: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      min: 5,
      inputAmount: 5,
      randomNumber: 0,

      trendRow: 6,
      trendCol: 15,

      showTrend: false,
    };
  },
  computed: {
    ...mapGetters({
      TypeBalanceSelected: "socket/TypeBalanceSelected",
    }),
    ForTrend() {
      const total = this.trendCol * this.trendRow;
      const history = [];
      const actualHistory = this.GameHistory.toReversed();
      for (let i = 0; i < total; i++) {
        history.push(actualHistory[i]?.color);
      }
      return history;
    },
    CountTrend() {
      const counts = {
        RED: 0,
        YELLOW: 0,
        GREEN: 0,
      };
      for (let i = 0; i < this.GameHistory.length; i++) {
        counts[this.GameHistory[i].color] += 1;
      }
      return {
        sonic: counts.GREEN,
        pika: counts.YELLOW,
        puff: counts.RED,
        total: this.GameHistory.length,
      };
    },

    ForStreak() {
      const col = this.trendCol;
      let pointer = 0;
      let buckets = [[]];
      const reversedHistory = this.GameHistory.toReversed();
      let streak = reversedHistory[0]?.color;

      for (let i = 0; i < reversedHistory.length; i += 1) {
        const color = reversedHistory[i].color;
        if (color === streak) {
          if (buckets[pointer].length >= this.trendRow) {
            pointer += 1;
            buckets[pointer] = [];
          }
          buckets[pointer].push(color);
        } else {
          pointer += 1;
          streak = color;
          buckets[pointer] = [];
          buckets[pointer].push(color);
        }
      }

      buckets.reverse();
      buckets = buckets.slice(0, col);
      buckets.reverse();

      const final = [];
      for (let i = 0; i < this.trendCol; i += 1) {
        for (let u = 0; u < this.trendRow; u += 1) {
          if (buckets[i] === undefined || buckets[i][u] === undefined) {
            final.push(undefined);
          } else {
            final.push(buckets[i][u]);
          }
        }
      }
      return final;
    },

    CoundownLine() {
      const percent = 1 - this.Countdown / this.TotalCountdown;
      return percent * 50.25;
    },
    Wheeling() {
      if (this.GameStatus === "START") {
        return this.randomNumber;
      } else if (this.GameStatus === "END" && this.GameResult) {
        return this.GameResult;
      } else {
        return -1;
      }
    },
    ShownRandom() {
      const theString = this.randomNumber.toString();

      if (theString.length <= 1) {
        return `0${theString}`;
      }
      return theString;
    },
    ShownResult() {
      const theString = this.GameResult.toString();

      if (theString.length <= 1) {
        return `0${theString}`;
      }
      return theString;
    },
  },
  watch: {
    GameStatus(newVal) {
      if (newVal === "START") {
        this.generateRandom();
      }
    },
  },
  methods: {
    betRed(e) {
      let p = this.toSVGPoint(
        e.target.closest(".gameTable"),
        e.clientX,
        e.clientY
      );

      this.$emit("userBet", {
        betType: "red",
        formBet: {
          currency: this.TypeBalanceSelected,
          amountBet: this.inputAmount,
        },
        coinX: p.x,
        coinY: p.y,
      });
    },
    betYellow(e) {
      let p = this.toSVGPoint(
        e.target.closest(".gameTable"),
        e.clientX,
        e.clientY
      );
      this.$emit("userBet", {
        betType: "yellow",
        formBet: {
          currency: this.TypeBalanceSelected,
          amountBet: this.inputAmount,
        },
        coinX: p.x,
        coinY: p.y,
      });
    },
    betGreen(e) {
      let p = this.toSVGPoint(
        e.target.closest(".gameTable"),
        e.clientX,
        e.clientY
      );
      this.$emit("userBet", {
        betType: "green",
        formBet: {
          currency: this.TypeBalanceSelected,
          amountBet: this.inputAmount,
        },
        coinX: p.x,
        coinY: p.y,
      });
    },
    divide() {
      if (this.inputAmount < this.min * 2) {
        this.inputAmount = this.min;
      } else {
        this.inputAmount = this.inputAmount / 2;
      }
    },
    multiply() {
      this.inputAmount = this.inputAmount * 2;
    },
    generateRandom() {
      this.randomNumber = Math.floor(Math.random() * 100);
      if (this.GameStatus === "START") {
        setTimeout(() => {
          this.generateRandom();
        }, 200);
      }
    },

    toSVGPoint(svg, x, y) {
      let p = new DOMPoint(x, y);
      return p.matrixTransform(svg.getScreenCTM().inverse());
    },
  },
};
</script>

<template>
  <div>
    <div class="position-relative">
      <svg class="gameTable" stroke="transparent" viewBox="0 0 100 50">
        <path
          stroke="rgba(93, 184, 251, 0.42)"
          fill="#0E142D"
          d="
              M 100,0
              A 50,50 0 0 1 0,0
            "
        />

        <!-- Red section -->
        <g
          @click="betRed"
          class="red overflow-hidden"
          :class="{
            light: Wheeling >= 55,
            locked: !(this.GameStatus === 'PENDING'),
          }"
          stroke-width="0.6"
          fill="url(#redGradient)"
        >
          <defs>
            <radialGradient
              cx="50%"
              cy="0"
              r="50"
              id="redGradient"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="30%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="first" stop-color="#0E142D" />
              <stop offset="100%" class="first" stop-color="#0E142D" />
            </radialGradient>
            <clipPath id="redClip">
              <path
                d="
                    M 50,0
                    L 100,0
                    A 50,50 0 0 1 75,43.30127
                    Z
                "
              />
            </clipPath>
          </defs>
          <path
            opacity="0.6"
            d="
                M 50,0
                L 100,0
                A 50,50 0 0 1 75,43.30127
                Z
              "
          />
          <path
            stroke="rgba(93, 184, 251, 0.42)"
            d="
              M 50,0
              L 75,43.30127
            "
          />

          <g clip-path="url(#redClip)">
            <image
              class="character"
              opacity="0.5"
              :href="require(`@/assets/images/jigglypuff.png`)"
              x="55"
              y="17"
              width="40"
              height="40"
              transform="rotate(-40)"
            />
          </g>

          <path
            id="redSet"
            fill="transparent"
            d="
              M  60, 17.32051
              A 20,20 0 0 0 70, 0
  
            "
          />
          <text font-size="3px" text-anchor="middle" fill="white">
            <textPath startOffset="10" href="#redSet">55 - 99</textPath>
          </text>

          <path
            fill="transparent"
            id="redPayout"
            d="
              M 74,41.56922
              A 48,48 0 0 0 98, 0
            "
          />
          <text
            font-size="3px"
            font-weight="700"
            text-anchor="middle"
            fill="#FF0E0E"
          >
            <textPath startOffset="25" href="#redPayout">1.95x</textPath>
          </text>

          <use v-if="UserBet.redBet" x="80" y="16" href="#myCoin" />
        </g>

        <!-- Yellow section -->
        <g
          @click="betYellow"
          class="yellow"
          :class="{
            light: Wheeling >= 45 && Wheeling < 55,
            locked: !(this.GameStatus === 'PENDING'),
          }"
          stroke-width="0.6"
          fill="url(#yellowGradient)"
        >
          <defs>
            <radialGradient
              cx="50%"
              cy="0"
              r="50"
              id="yellowGradient"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="30%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="first" stop-color="#0E142D" />
              <stop offset="100%" class="first" stop-color="#0E142D" />
            </radialGradient>

            <clipPath id="yellowClip">
              <path
                d="
                    M 50,0
                    L 75,43.30127
                    A 50,50 0 0 1 25,43.30127
                    Z
                "
              />
            </clipPath>
          </defs>
          <path
            opacity="0.6"
            d="
                M 50,0
                L 75,43.30127
                A 50,50 0 0 1 25,43.30127
                Z
              "
          />
          <path
            stroke="rgba(93, 184, 251, 0.42)"
            d="
              M 50,0
              L 25,43.30127
            "
          />

          <g clip-path="url(#yellowClip)">
            <image
              class="character"
              opacity="0.5"
              :href="require(`@/assets/images/pikachu.png`)"
              y="12"
              x="32"
              width="40"
              height="40"
            />
          </g>

          <path
            id="yellowSet"
            fill="transparent"
            d="
              M  40, 17.32051
              A 20,20 0 0 0 60, 17.32051
  
            "
          />
          <text font-size="3px" text-anchor="middle" fill="white">
            <textPath startOffset="10" href="#yellowSet">45 - 54</textPath>
          </text>

          <path
            fill="transparent"
            id="yellowPayout"
            d="
              M 26,41.56922
              A 48,48 0 0 0 74,41.56922
            "
          />
          <text
            font-size="3px"
            font-weight="700"
            text-anchor="middle"
            fill="#FFC700"
          >
            <textPath startOffset="25" href="#yellowPayout">8x</textPath>
          </text>

          <use v-if="UserBet.yellowBet" x="50" y="35" href="#myCoin" />
        </g>

        <!-- Green section -->
        <g
          @click="betGreen"
          class="green"
          :class="{
            light: Wheeling >= 0 && Wheeling < 45,
            locked: !(this.GameStatus === 'PENDING'),
          }"
          stroke-width="0.6"
          fill="url(#greenGradient)"
        >
          <defs>
            <radialGradient
              cx="50%"
              cy="0"
              r="50"
              id="greenGradient"
              gradientUnits="userSpaceOnUse"
              spread-method="repeat"
            >
              <stop offset="30%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="first" stop-color="#0E142D" />
              <stop offset="45%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="second" stop-color="#1B2C57" />
              <stop offset="60%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="first" stop-color="#0E142D" />
              <stop offset="75%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="second" stop-color="#1B2C57" />
              <stop offset="90%" class="first" stop-color="#0E142D" />
              <stop offset="100%" class="first" stop-color="#0E142D" />
            </radialGradient>
            <clipPath id="greenClip">
              <path
                d="
                    M 50,0
                    L 25,43.30127
                    A 50,50 0 0 1 0,0
                    Z
                "
              />
            </clipPath>
          </defs>
          <path
            opacity="0.6"
            d="
                M 50,0
                L 25,43.30127
                A 50,50 0 0 1 0,0
                Z
              "
          />

          <g clip-path="url(#greenClip)">
            <image
              class="character"
              opacity="0.5"
              width="55"
              height="55"
              x="20"
              y="-15"
              :href="require(`@/assets/images/sonic.png`)"
              transform="rotate(60)"
            />
          </g>

          <path
            id="greenSet"
            fill="transparent"
            d="
              M  30, 0
              A 20,20 0 0 0 40, 17.32051
  
            "
          />
          <text font-size="3px" text-anchor="middle" fill="white">
            <textPath startOffset="10" href="#greenSet">00 - 44</textPath>
          </text>

          <path
            fill="transparent"
            id="greenPayout"
            d="
              M 2,0
              A 48,48 0 0 0 26,41.56922
            "
          />
          <text
            font-size="3px"
            font-weight="700"
            text-anchor="middle"
            fill="#0EFF9A"
          >
            <textPath startOffset="25" href="#greenPayout">1.95x</textPath>
          </text>

          <use v-if="UserBet.greenBet" x="20" y="16" href="#myCoin" />
        </g>

        <!-- Inner circle -->
        <path
          fill="#0E142D"
          stroke="#050812"
          stroke-width="0.5"
          d="
            M 34,0
            A 16,16 0 0 0 66,0
          "
        />
        <defs>
          <pattern
            id="img1"
            patternUnits="userSpaceOnUse"
            width="100"
            height="100"
          >
            <image
              :href="require(`@/assets/images/currency/bsBg.png`)"
              x="35"
              y="-15"
              width="30"
              height="30"
            />
          </pattern>
        </defs>

        <!-- Countdown bar -->
        <path
          fill="url(#img1)"
          stroke="url(#linearGrad)"
          stroke-width="0.5"
          stroke-dasharray="50.25"
          :stroke-dashoffset="CoundownLine"
          style="transition: 1s linear"
          d="
            M 34,0
            A 16,16 0 0 0 66,0
          "
        />
        <defs>
          <linearGradient
            id="linearGrad"
            x1="3.14941"
            y1="0.810364"
            x2="168.908"
            y2="159.299"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3964FA" />
            <stop offset="1" stop-color="#12BAFE" />
          </linearGradient>
        </defs>

        <!-- Game Status top -->
        <g v-if="GameStatus === 'PENDING'">
          <text x="50" y="5" font-size="4px" text-anchor="middle" fill="white">
            Wowing...
          </text>
          <text
            x="50"
            y="10"
            font-size="4px"
            font-weight="600"
            text-anchor="middle"
            fill="#10D3FE"
          >
            {{ Countdown }}
          </text>
        </g>
        <g v-if="GameStatus === 'START'">
          <text
            x="50"
            y="8"
            font-size="6px"
            text-anchor="middle"
            :fill="
              ShownRandom >= 55
                ? '#FF0E0E'
                : ShownRandom >= 45
                ? '#FFC700'
                : '#0EFF9A'
            "
          >
            {{ ShownRandom }}
          </text>
        </g>
        <g v-if="GameStatus === 'END'">
          <text
            x="50"
            y="8"
            font-size="6px"
            text-anchor="middle"
            :fill="
              GameResult >= 55
                ? '#FF0E0E'
                : GameResult >= 45
                ? '#FFC700'
                : '#0EFF9A'
            "
          >
            {{ ShownResult }}
          </text>
        </g>
        <g v-if="GameStatus === 'CONNECTING'">
          <text x="50" y="5" font-size="4px" text-anchor="middle" fill="white">
            CONNECTING
          </text>
        </g>

        <defs>
          <g id="myCoin">
            <image
              v-if="UserBet.currency"
              x="-3"
              y="-3"
              width="6"
              height="6"
              :href="
                require(`@/assets/images/currency/${UserBet.currency.toLowerCase()}.png`)
              "
            />
            <text font-size="3" text-anchor="middle" fill="white" y="6">
              {{ UserBet.greenBet || UserBet.yellowBet || UserBet.redBet }}
            </text>
          </g>
        </defs>
      </svg>
    </div>

    <div class="action-sect">
      <div v-if="showTrend" class="game-history">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center justify-content-end">
            <div class="px-1">
              <img src="@/assets/images/sonicButton.png" alt="" />
              {{ CountTrend.sonic }}
              ({{ ((CountTrend.sonic * 100) / CountTrend.total).toFixed(1) }}%)
            </div>
            <div class="px-1">
              <img src="@/assets/images/puffButton.png" alt="" />
              {{ CountTrend.puff }}
              ({{ ((CountTrend.puff * 100) / CountTrend.total).toFixed(1) }}%)
            </div>
            <div class="px-1">
              <img src="@/assets/images/pikaButton.png" alt="" />
              {{ CountTrend.pika }}
              ({{ ((CountTrend.pika * 100) / CountTrend.total).toFixed(1) }}%)
            </div>
          </div>
          <div>
            <div class="trend-table">
              <div
                v-for="(item, index) in ForTrend"
                :key="index"
                :style="{ color: item }"
                class="trend-item"
              >
                <img
                  v-if="item"
                  :src="
                    require(`@/assets/images/${
                      item === 'RED'
                        ? 'puffButton'
                        : item === 'YELLOW'
                        ? 'pikaButton'
                        : 'sonicButton'
                    }.png`)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          Streak:
          <div>
            <div class="trend-table">
              <div
                v-for="(item, index) in ForStreak"
                :key="index"
                :style="{ color: item }"
                class="trend-item"
              >
                <img
                  v-if="item"
                  :src="
                    require(`@/assets/images/${
                      item === 'RED'
                        ? 'puffButton'
                        : item === 'YELLOW'
                        ? 'pikaButton'
                        : 'sonicButton'
                    }.png`)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        {{ $t("wallet.amount") }}
        <div class="input-box">
          <img
            class="currency"
            :src="
              require(`@/assets/images/currency/${TypeBalanceSelected.toLowerCase()}.png`)
            "
            alt=""
          />
          <input
            type="number"
            :min="min"
            onwheel="this.blur()"
            class="input"
            v-model="inputAmount"
          />
          <button @click="divide" class="botan">1/2</button>
          <button @click="multiply" class="botan">x2</button>
        </div>
        <button class="botan trendTog" @click="showTrend = !showTrend">
          {{ $t("bitkachu.showTrend") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-sect {
  font-size: 17px;
  font-weight: 500;
  padding: 0px 15px;
  padding-top: 20px;
  .trendTog {
    background-color: #1b2c57;
    padding: 10px;
    margin-top: 15px;
  }
  .input-box {
    max-width: 350px;
    margin: auto;
    margin-top: 15px;
    display: flex;
    border-radius: 7px;
    border: 1px solid rgba(93, 184, 251, 0.42);
    background: #0e142d;
    padding: 0;
    .botan {
      border: 1px solid rgba(93, 184, 251, 0.42);
      background: #495a88;
      padding: 10px 15px;
      transition: 0.1s;
      &:last-child {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      &:hover {
        background: #5b70a9;
      }
      &:active {
        background: #3b496f;
      }
    }
    .currency {
      margin-left: 10px;
      margin-right: 5px;
      width: 24px;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }
  @media (min-width: 992px) {
    padding-top: 45px;
  }
}
text {
  background-color: blue;
}
.gameTable {
  overflow: visible;
  width: 100%;
  padding: 0px 15px;
  g {
    transition: 0.2s;
    cursor: pointer;
    stop {
      transition: 0.2s;
    }
    path {
      transition: 0.2s;
    }
    &.light {
      path {
        opacity: 1;
      }
      .character {
        opacity: 1;
      }
      stop {
        transition: 0.05s;
      }
    }

    &:hover {
      path {
        opacity: 1;
      }
      .character {
        opacity: 1;
      }
    }
    &:active {
      filter: brightness(0.9);
    }

    &.locked {
      pointer-events: none;
    }
    // &.red {
    //   &:hover,
    //   &.light {
    //     .first {
    //       stop-color: #3d1313;
    //     }
    //     .second {
    //       stop-color: #571b1b;
    //     }
    //   }
    // }
    // &.green {
    //   &:hover,
    //   &.light {
    //     .first {
    //       stop-color: #164716;
    //     }
    //     .second {
    //       stop-color: #236f25;
    //     }
    //   }
    // }
    // &.yellow {
    //   &:hover,
    //   &.light {
    //     .first {
    //       stop-color: #413c14;
    //     }
    //     .second {
    //       stop-color: #68651f;
    //     }
    //   }
    // }
  }
}
.game-history {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  > div {
    max-width: 450px;
  }
  .trend-table {
    --col: v-bind(trendCol);
    --row: v-bind(trendRow);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(var(--col), 1fr);
    grid-template-rows: repeat(var(--row), auto);
    width: 100%;
    padding: 15px 0px;
    overflow: hidden;
    justify-content: start;
    .trend-item {
      aspect-ratio: 1;
      position: relative;
      color: transparent;
      border: 1px solid rgba(93, 184, 251, 0.35);
      img {
        position: absolute;
        object-fit: contain;
        width: 100%;
        aspect-ratio: 1;
        user-select: none;
      }
      // &::after {
      //   position: absolute;
      //   content: "";
      //   inset: 4px;
      //   border-radius: 100vw;
      //   background-color: currentColor;
      // }
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.character {
  transition: 0.2s;
  transform-box: fill-box;
}
</style>
